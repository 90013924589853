import {ethers} from "ethers";
import {ProviderType} from "hooks/utils/useProvider";

export const useBalance = () => {
    const callback: UseBalanceCallbackType = async (provider, address) => {
        const balance = await provider.getBalance(address);
        return +(ethers.utils.formatUnits(balance, "ether"));
    };

    return callback;
};

export type UseBalanceCallbackType = (provider: ProviderType, address: string) => Promise<number>