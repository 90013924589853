import {Dispatch, SetStateAction, useContext} from "react";
import {MetamaskContext} from "providers/MetamaskProvider";
import {AdminPanelServices} from "API/AdminPanel";
import {ContractContext} from "providers/ContractProvider";
import {NotificationContext} from "providers/NotificationProvider";
import {UserContext} from "providers/UserProvider";
import {AlertModalType} from "components/Modals/Alert/ModalAlert";
import {useProcessingToStorage} from "hooks/utils/useProcessingToStorage";
import {StorageContext} from "providers/StorageProvider";
import {BulkStatusType} from "providers/NFTsProvider";

export const useStaking = () => {
    const {provider, chainIdEth, address, switchNetwork} = useContext(MetamaskContext);
    const {
        errorInsufficientFunds,
        errorApprovalForAll,
        errorStaking,
        errorNetwork,
        successStaking
    } = useContext(NotificationContext);
    const {
        contractStakingABI,
        contractStakingAddress,
        contractLadyAddress,
        contractBoyAddress,
        contractBoyABI,
        contractLadyABI,
        createContract,
        getAddressBalance,
    } = useContext(ContractContext);
    const {logoutUnauthorized} = useContext(UserContext);
    const {storageKeyProcessingNotStaked} = useContext(StorageContext);
    const processingToStorage = useProcessingToStorage();

    const callback: AddToStakingType = async (
        stakingDays,
        tokenId,
        id,
        type,
        contractAddress,
        {setIsProcessing, setNotificationInfo, setIsNotificationOpen, setBulkNFTsProcessing},
    ) => {
        try {
            setIsProcessing(true);

            try {
                await switchNetwork(provider, chainIdEth);
            } catch (e) {
                setNotificationInfo(errorNetwork);
                throw errorNetwork.text;
            }

            processingToStorage(storageKeyProcessingNotStaked, {tokenId, type}, true);

            const {
                web3Provider,
                contract: contractStaking
            } = createContract(provider, contractStakingAddress, contractStakingABI);
            const {contract: contractIggyBoy} = createContract(provider, contractBoyAddress, contractBoyABI);
            const {contract: contractIggyLady} = createContract(provider, contractLadyAddress, contractLadyABI);

            const contracts = [contractIggyBoy, contractIggyLady];
            const contract = contracts[type];

            const responseApproval = await AdminPanelServices.isApproval(contractAddress);
            const isApproval = responseApproval.data.approval;

            if (!isApproval) {
                try {
                    const trxApproval = await contract.setApprovalForAll(contractStakingAddress, true);
                    await trxApproval.wait();
                    await AdminPanelServices.addApproval(contractAddress);
                } catch (error: any) {
                    if (error.code === 4001) throw 4001;

                    setNotificationInfo(errorApprovalForAll);
                    logoutUnauthorized(error);
                    throw errorApprovalForAll.text;
                }
            }

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setNotificationInfo(errorInsufficientFunds);
                throw errorInsufficientFunds.text;
            }

            const overrides = {
                gasLimit: 1_000_000,
            };

            try {
                const trxStake = await contractStaking.stake(tokenId, type, stakingDays, overrides);
                await trxStake.wait();
            } catch (error) {
                setNotificationInfo(errorStaking);
                throw errorStaking.text;
            }

            setNotificationInfo(successStaking);
        } catch (error) {
            console.log(error);

            processingToStorage(storageKeyProcessingNotStaked, {tokenId, type}, false);

            setBulkNFTsProcessing(prevState => {
                return prevState.filter(processedNFT => processedNFT.tokenId !== tokenId && processedNFT.id !== id);
            });

            logoutUnauthorized(error);

            setIsProcessing(false);

            if (error === 4001) setNotificationInfo(errorStaking);
        } finally {
            setIsNotificationOpen(true);
        }
    };

    return callback;
};

export type AddToStakingType = (
    stakingDays: StakingDaysType,
    tokenId: string,
    id: number,
    type: number,
    contractAddress: string,
    events: EventsHookType,
) => void

export type EventsHookType = {
    setIsProcessing: Dispatch<SetStateAction<boolean>>
    setNotificationInfo: Dispatch<SetStateAction<AlertModalType>>
    setIsNotificationOpen: Dispatch<SetStateAction<boolean>>
    setBulkNFTsProcessing: Dispatch<SetStateAction<BulkStatusType>>
}

export type StakingDaysType = 30 | 90