import React from "react";

type StatusBuilderType = {
    type: StatusType
}

const Status = ({type}: StatusBuilderType) => {
    return (
        <div className={`status status--${type}`}>{type}</div>
    );
};

export default Status;

export type StatusType = "locked" | "unlocked"