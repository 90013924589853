import React, {useEffect, useState} from "react";

type IndicatorType = { visible: number }

const ProcessingLoader = () => {
    const [indicators, setIndicators] = useState<IndicatorType[]>([
        {visible: 1},
        {visible: 0.3},
        {visible: 0.4},
        {visible: 0.5},
        {visible: 0.6},
        {visible: 0.7},
        {visible: 0.8},
        {visible: 0.9},
    ]);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndicators(prevState => {
                const newState: IndicatorType[] = [];

                prevState.forEach((state, index) => {
                    const lastElement = prevState.length - 1;
                    const firstElement = 0;
                    const prevElement = index - 1;
                    const result = {visible: 0};

                    if (index === firstElement) {
                        result.visible = prevState[lastElement].visible;
                        newState.push(result)
                        return state;
                    }

                    result.visible = prevState[prevElement].visible;

                    newState.push(result)
                    return state;
                });

                return [...newState];
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="loader-processing">
            <div className="loader-processing__wrapper">
                {
                    indicators.map((indicator, index) => (
                        <span key={index} style={{opacity: indicator.visible}} className="loader-processing__item"/>
                    ))
                }
            </div>
        </div>
    );
};

export default ProcessingLoader;