import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import CardNFT from "components/NFTs/Cards/helper/CardNFT";
import ProcessInfo from "components/NFTs/ProcessInfo";
import ModalAlertNotification from "components/Modals/Alert/ModalAlertNotification";
import InputCheckbox from "components/UI/Inputs/InputCheckbox";
import {BulkNFTsType, NFTsContext} from "providers/NFTsProvider";
import {AlertModalType} from "components/Modals/Alert/ModalAlert";
import {BulkEventsType, useBulkSelect} from "hooks/utils/useBulkSelect";
import {NotificationContext} from "providers/NotificationProvider";
import {EventsHookType} from "hooks/useStaking";
import {StorageContext, StorageProcessingNFTsKeyType} from "providers/StorageProvider";

type CardWrapperProps = {
    storageKey: StorageProcessingNFTsKeyType
    setCallback: Dispatch<SetStateAction<BulkEventsType>>
    nft: BulkNFTsType
    children: React.ReactNode
    image: string
    name: string
    showCheckbox: boolean
}

const CardWrapper = (
    {
        storageKey,
        setCallback,
        nft,
        showCheckbox,
        image,
        name,
        children,
    }: CardWrapperProps
) => {
    const {processingStatus} = useContext(NotificationContext);
    const {
        bulkNFTs,
        setBulkNFTs,
        setBulkNFTsProcessing,
    } = useContext(NFTsContext);
    const {getStorageByKey} = useContext(StorageContext);

    const [processingInfoText, setProcessingInfoText] = useState(processingStatus);
    const [isChecked, setIsChecked] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);
    const [notificationInfo, setNotificationInfo] = useState<AlertModalType>({
        text: "",
        title: "",
        variant: "Error",
        link: null,
    });

    const events: BulkEventsType = {
        isChecked,
        setIsChecked,

        isProcessing,
        setIsProcessing,

        processingInfoText,
        setProcessingInfoText,

        isNotificationOpen,
        setIsNotificationOpen,

        notificationInfo,
        setNotificationInfo,

        bulkNFTs,
        setBulkNFTs,

        setBulkNFTsProcessing,
    };
    const eventsDependencies = [
        isChecked,
        setIsChecked,

        isProcessing,
        setIsProcessing,

        processingInfoText,
        setProcessingInfoText,

        isNotificationOpen,
        setIsNotificationOpen,

        notificationInfo,
        setNotificationInfo,

        bulkNFTs,
        setBulkNFTs,

        setBulkNFTsProcessing,
    ];

    const {handleSelect} = useBulkSelect(nft, events);

    useEffect(() => {
        setCallback(events);
    }, eventsDependencies);

    // Check processing status from local storage for card nft - COMPLETED
    useEffect(() => {
        const key = nft.token_id + "_" + nft.type;
        const storage = getStorageByKey(storageKey);
        const itemTimer = storage[key] as number;

        if (itemTimer !== undefined && itemTimer > Date.now()) {
            setBulkNFTsProcessing(prevState => [...prevState, {tokenId: nft.token_id, id: nft.id}]);
        }
    }, []);

    return (
        <CardNFT isChecked={isChecked} isDisabled={isProcessing} showCheckbox={showCheckbox} image={image} name={name} onChange={showCheckbox ? handleSelect : () => {}}>
            {
                isProcessing
                    ? <ProcessInfo text={processingInfoText}/>
                    : <>
                        {
                            showCheckbox && <div className="card-nft__checkbox">
                                <InputCheckbox isChecked={isChecked} onChange={handleSelect}/>
                            </div>
                        }

                        {children}
                    </>
            }

            <ModalAlertNotification
                isOpen={isNotificationOpen}
                setIsOpen={setIsNotificationOpen}
                {...notificationInfo}
            />
        </CardNFT>
    );
};

export default CardWrapper;

export type GetEventsCallbackType = () => EventsHookType
