import React, {useState} from "react";
import BulkExtendTo90Days from "components/Bulk/BulkExtendTo90Days";
import {SelectOptionType} from "components/UI/Inputs/InputSelect";
import SortByIds from "components/Sort/SortByIds";
import SortBy from "components/Sort/SortBy";
import SortByStatus from "components/Sort/SortByStatus";
import PanelNFTsWrapper, {ResetPanelCallbackType} from "components/Panel/helper/PanelNFTsWrapper";
import {AdminPanelServices} from "API/AdminPanel";
import {GetNFTsStakeResponseType, TypeStakeNFT} from "API/AdminPanel/types";

const StakedPanel = () => {
    const [selectedOptionByOrder, setSelectedOptionByOrder] = useState(optionsByOrder[0]);
    const [selectedOptionByCollection, setSelectedOptionByCollection] = useState(optionsByCollection[0]);
    const [selectedOptionByStatus, setSelectedOptionByStatus] = useState(optionsByStatus[0]);
    const [selectedByIds, setSelectedByIds] = useState("");

    const [reset, setReset] = useState<ResetPanelCallbackType>({} as ResetPanelCallbackType);

    const callback = async (page: number) => {
        const byOrder = selectedOptionByOrder.value;
        const byCollection = +selectedOptionByCollection.value;
        const byStatus = selectedOptionByStatus.value;

        return await AdminPanelServices.getNFTsStake(page, byOrder, byCollection, selectedByIds, byStatus);
    };

    return (
        <PanelNFTsWrapper<TypeStakeNFT, GetNFTsStakeResponseType>
            title="Staked" resetCallback={setReset} callback={callback} type="Staked">
            <div className="nft__filter-options">
                <SortByStatus callback={reset} options={optionsByStatus}
                              selected={selectedOptionByStatus} setSelected={setSelectedOptionByStatus}/>
            </div>

            <div className="nft__sort">
                <SortByIds callback={reset} selected={selectedByIds} setSelected={setSelectedByIds}/>

                <SortBy callback={reset} options={optionsByOrder}
                        selected={selectedOptionByOrder} setSelected={setSelectedOptionByOrder}/>
                <SortBy callback={reset} options={optionsByCollection}
                        selected={selectedOptionByCollection} setSelected={setSelectedOptionByCollection}/>
            </div>

            <BulkExtendTo90Days byStatus={selectedOptionByStatus.value} byIds={selectedByIds}
                                byCollection={+selectedOptionByCollection.value}/>
        </PanelNFTsWrapper>
    );
};

export default StakedPanel;

const optionsByOrder: SelectOptionType[] = [
    {label: 'Alphabetical A-Z', value: JSON.stringify({sortName: "asc"})},
    {label: 'Alphabetical Z-A', value: JSON.stringify({sortName: "desc"})},

    {label: 'Oldest First', value: JSON.stringify({sortDate: "asc"})},
    {label: 'Recent First', value: JSON.stringify({sortDate: "desc"})},

    {label: '30 Days First', value: JSON.stringify({sortStakingDay: "asc"})},
    {label: '90 Days First', value: JSON.stringify({sortStakingDay: "desc"})},
];

const optionsByCollection: SelectOptionType[] = [
    {label: 'IggyBoy', value: '1'},
    {label: 'IggyLady', value: '2'},
];

const optionsByStatus: SelectOptionType[] = [
    {label: "all", value: ""},
    {label: "All staked with $FRBI locked", value: "asc"},
    {label: "With $Freebi unlocked", value: "desc"},
];