import React, {useContext} from "react";
import icons from "components/UI/icons";
import {ModalContext} from "providers/ModalProvider";
import ModalAlertWrapper from "components/Modals/helper/ModalAlertWrapper";

const ModalAlert = () => {
    const {alertModal, isAlertModalOpen, setIsAlertModalOpen} = useContext(ModalContext);

    const createIcon = (variant: VariantTypeValue): AlertIconType => {
        return "Icon" + variant as AlertIconType;
    };

    const iconName = createIcon(alertModal.variant);
    const Icon = icons[iconName];

    return (
        <ModalAlertWrapper isOpen={isAlertModalOpen} closeModal={setIsAlertModalOpen}>
            <div onClick={
                (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
            } className="modal__wrapper modal__alert">
                <button onClick={() => setIsAlertModalOpen(false)} className="modal__btn-close">close</button>

                <div className="modal__inner">
                    <div className={`modal__icon icon icon--small icon--${alertModal.variant.toLowerCase()}`}>
                        <Icon/>
                    </div>

                    <div className="modal__alert-info">
                        <h3 className={`h2 modal__alert-title modal__alert-title--${alertModal.variant.toLowerCase()}`}>{alertModal.title}</h3>
                        <p className="modal__alert-text">{alertModal.text}</p>
                    </div>
                </div>

                {
                    alertModal.link?.text &&
                    alertModal.link?.href &&
                    <a className="modal__alert-link button button--primary"
                       href={alertModal.link.href}
                       target="_blank"
                       rel="noreferrer">
                        {alertModal.link.text}
                    </a>
                }
            </div>
        </ModalAlertWrapper>
    );
};

export default ModalAlert;

export type VariantType = {
    variant: "Error" | "Success"
}

export type VariantTypeValue = VariantType[keyof VariantType];

export interface AlertModalType extends VariantType {
    text: string
    title: string
    link: AlertModalLink | null
}

export type AlertModalLink = {
    text: string
    href: string
}

export type AlertIconType = keyof Pick<typeof icons, "IconSuccess" | "IconError">;
