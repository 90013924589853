import React, {useEffect, useRef, useState} from "react";

export const useResize: UseResizeType = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState(0);

    useEffect(() => {
        setSize(ref.current?.offsetWidth as number);

        window.addEventListener("resize", () => {
            setSize(ref.current?.offsetWidth as number);
        });
    }, []);

    return {ref, size};
};

type UseResizeType = () => ({ref: React.RefObject<HTMLDivElement>, size: number})