import {ethers} from "ethers";
import {ContractABIType} from "providers/ContractProvider";
import {ProviderType} from "hooks/utils/useProvider";

export const useContract = () => {
    const callback: UseContractCallbackType = (provider, contractAddress, contractABI) => {
        const web3Provider = new ethers.providers.Web3Provider(provider);
        const signer = web3Provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        return {web3Provider, signer, contract};
    };

    return callback;
}

export type UseContractCallbackType = (provider: ProviderType, contractAddress: string, contractABI: ContractABIType) => {
    web3Provider: ethers.providers.Web3Provider
    signer: ethers.providers.JsonRpcSigner
    contract: ethers.Contract
}