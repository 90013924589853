import {Dispatch, SetStateAction, useContext} from "react";
import {AdminPanelServices} from "API/AdminPanel";
import {ModalContext} from "providers/ModalProvider";
import {NotificationContext} from "providers/NotificationProvider";
import {MetamaskContext} from "providers/MetamaskProvider";
import {StorageContext, WithdrawStatusType} from "providers/StorageProvider";
import {UserContext} from "providers/UserProvider";
import {sha256} from "js-sha256";

export const useWithdrawFunds = () => {
    const {setAlertModal, setIsAlertModalOpen} = useContext(ModalContext);
    const {userFund, logoutUnauthorized} = useContext(UserContext);
    const {address} = useContext(MetamaskContext);
    const {
        setProcessingWithdraw,
        storageKeyProcessingWithdraw,
        getStorageByKey,
        isWithdrawProcessing,
        setIsWithdrawProcessing
    } = useContext(StorageContext);
    const {
        errorWithdrawNoCoins,
        errorWithdrawFunds,
        errorMoreTokensWithdraw,
        successWithdrawFunds,
    } = useContext(NotificationContext);

    const callback: WithdrawFundsType = async (
        funds: number,
        setIsProcessing: Dispatch<SetStateAction<boolean>>,
    ) => {
        try {
            console.log('isWithdrawProcessing', isWithdrawProcessing)
            setIsProcessing(true);

            const currentFunds = userFund.unlocked;

            if (currentFunds <= 0) {
                setAlertModal(errorWithdrawNoCoins);
                throw errorWithdrawNoCoins.text;
            }

            if (funds > currentFunds) {
                setAlertModal(errorMoreTokensWithdraw);
                throw errorMoreTokensWithdraw.text;
            }

            const hashAddress = sha256(address);
            const storage = getStorageByKey(storageKeyProcessingWithdraw);

            const withdrawStatus = storage[hashAddress] as WithdrawStatusType;
            const withdrawHash = withdrawStatus !== undefined ? withdrawStatus.hash : "";

            try {
                setProcessingWithdraw(address, "", true);

                const response = await AdminPanelServices.sendFund(address, withdrawHash, funds);

                setProcessingWithdraw(address, response.data.hash, true);
                setIsWithdrawProcessing(true);
            } catch (error: any) {
                console.log('error', error)
                setAlertModal({
                    title: errorWithdrawFunds.title,
                    link: errorWithdrawFunds.link,
                    variant: errorWithdrawFunds.variant,
                    text: error.response.data.message
                });
                logoutUnauthorized(error);
                throw error.response.data.message;
            }

            setAlertModal(successWithdrawFunds);
        } catch (error) {
            console.debug(error);
            setIsWithdrawProcessing(false);
            setProcessingWithdraw(address, "", false);
        } finally {
            setIsProcessing(false);
            setIsAlertModalOpen(true);
        }
    };

    return callback;
};

export type WithdrawFundsType = (
    funds: number,
    setIsProcessing: Dispatch<SetStateAction<boolean>>,
) => void;