import React, {useContext} from "react";
import ModalConfirmBuilder from "components/Modals/helper/helper/ModalСonfirmBuilder";
import {TransactionContext} from "providers/TransactionProvider";
import {EventsHookType} from "hooks/useStaking";
import {ModalOpenStatus} from "providers/ModalProvider";
import {BulkEventsType} from "hooks/utils/useBulkSelect";
import {NotificationContext} from "providers/NotificationProvider";
import {NFTsContext} from "providers/NFTsProvider";

type ModalTerminateType = UnstakeModalType & ModalOpenStatus

const ModalTerminate = ({type, name, tokenId, id, index_array, events, setIsOpen, isOpen}: ModalTerminateType) => {
    const {addToUnstaking} = useContext(TransactionContext);
    const {processingUnstake} = useContext(NotificationContext);
    const {setBulkNFTs} = useContext(NFTsContext);

    const cancelModal = () => {
        setIsOpen(false);
    };

    const unstake = () => {
        cancelModal();

        if (events.isChecked) {
            setBulkNFTs(prevState => prevState.filter(item => item.token_id !== tokenId && item.id !== id));
        }

        events.setProcessingInfoText(processingUnstake);
        events.setBulkNFTsProcessing(prevState => ([...prevState, {tokenId, id}]));

        addToUnstaking(tokenId, id, type, index_array, events);
    };

    return (
        <ModalConfirmBuilder
            isOpen={isOpen}
            closeModal={setIsOpen}
            title="Unstake your NFT"
            text={`Are you sure you want to terminate the active staking process of the NFT ${name} Your locked coins will not be added to your balance?`}
            buttons={
                <div className="modal__buttons">
                    <button onClick={cancelModal} className="modal__btn button button--tertiary">
                        Cancel
                    </button>

                    <button onClick={unstake} className="modal__btn button button--primary">
                        Yes, unstake
                    </button>
                </div>
            }/>
    );
};

export default ModalTerminate;

type UnstakeModalType = {
    tokenId: string
    id: number
    type: number
    index_array: number
    name: string
    events: BulkEventsType
}