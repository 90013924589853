import React, {MouseEvent, useContext, useEffect, useRef, useState} from "react";
import CardStaked from "components/NFTs/Cards/CardStaked";
import {TypeStakeNFT, TypeUnstakeNFT} from "API/AdminPanel/types";
import CardNotStaked from "components/NFTs/Cards/CardNotStaked";
import {PageNFTsTypes} from "components/Panel/helper/PanelNFTsWrapper";
import {BulkNFTsType, NFTsContext} from "../../../../providers/NFTsProvider";

type CardListProps<T> = {
    NFTs: T[]
    type: PageNFTsTypes
}
type SelectionRectangleType = {
    startX: number
    startY: number
    top: number
    left: number
    right: number
    bottom: number
}
const CardList = <T extends TypeUnstakeNFT | TypeStakeNFT>({NFTs, type}: CardListProps<T>) => {
    const { innerHeight } = window;
    const {bulkNFTs, setBulkNFTs} = useContext(NFTsContext);
    // const {heightDocument, setHeightDocument} = useState(null);
    const refListCards = useRef<HTMLUListElement | null>(null);
    const stepAnimate = useRef<number>(100);
    const cursorPosition = useRef({x:0, y:0});
    const animateScrollInterval = useRef<number | null>(null);
    const [animateScroll, setAnimateScroll] = useState(false);
    const isMouseDown = useRef(false);
    const selectionRectDiv = useRef<HTMLDivElement | null>(null);
    const arrNFTCardDiv = useRef(new Array(NFTs.length));
    const [arrPreviousBulkNFTs, setArrPreviousBulkNFTs] = useState<BulkNFTsType[]>([]);
    // const arrPreviousBulkNFTs = useRef<BulkNFTsType[]>([]<BulkNFTsType>);
    const [selectionRectangle, setSelectionRectangle] = useState<SelectionRectangleType>({
        startX: 0,
        startY: 0,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    });

    useEffect(() => {
        return () => {
            if (animateScrollInterval.current) {
                // console.debug('clearInterval')
                window.clearInterval(animateScrollInterval.current);
                animateScrollInterval.current = null;
            }
        }
    }, [])

    useEffect(() => {
        // console.debug('useEffect', animateScroll);

        if (animateScroll && !animateScrollInterval.current) {
            // console.debug('setInterval')
            animateScrollInterval.current = window.setInterval(animationScroll, 60);
        } else {
            if (animateScrollInterval.current) {
                // console.debug('clearInterval')
                window.clearInterval(animateScrollInterval.current);
                animateScrollInterval.current = null;
            }
        }
    }, [animateScroll])

    const animationScroll = () => {
        // console.debug('animationScroll', stepAnimate.current);
        if (window.scrollY >= 0) {
            window.scrollTo(0, window.scrollY + stepAnimate.current);
        }
        const event = new window.MouseEvent('mousemove', { bubbles: true, cancelable: true, view: window, clientX: cursorPosition.current.x, clientY: cursorPosition.current.y, buttons: 1 });
        refListCards.current?.dispatchEvent(event);
    }

    // function startAnimatitonScroll() {
    //     if (!animateScrollInterval.current) {
    //         console.debug('setInterval');
    //         animateScrollInterval.current = window.setInterval(animationScroll, 50);
    //     }
    // }
    //
    // function stopAnimatitonScroll() {
    //     if (animateScrollInterval.current) {
    //         console.debug('clearInterval')
    //         window.clearInterval(animateScrollInterval.current);
    //         animateScrollInterval.current = null;
    //     }
    // }

    function onMouseDown(e: MouseEvent) {
        // console.debug('onMouseDown', isMouseDown.current, e.buttons)
        e.preventDefault();

        setTimeout(() => {
            isMouseDown.current = true;
            setSelectionRectangle({
                startX: e.pageX,
                startY: e.pageY,
                top: e.pageY,
                left: e.pageX,
                right: 0,
                bottom: 0
            });
            setArrPreviousBulkNFTs([...bulkNFTs]);
        }, 10)
    }

    function onMouseMove(e: MouseEvent) {
        if (!(isMouseDown.current && e.buttons)) {
            return;
        }
        cursorPosition.current = {x: e.clientX, y: e.clientY};
        // console.debug('onMouseMove', isMouseDown.current, e.buttons, e.clientY)

        if (150 > e.clientY && e.clientY > 100) {
            stepAnimate.current = -40;
            setAnimateScroll(true);
            // startAnimatitonScroll();
        } else if (100 >= e.clientY) {
            stepAnimate.current = -100;
            setAnimateScroll(true);
            // startAnimatitonScroll();
        } else if (innerHeight - 100 < e.clientY && innerHeight - 50 < e.clientY) {
            stepAnimate.current = 100;
            setAnimateScroll(true);
            // startAnimatitonScroll();
        } else if (innerHeight - 100 < e.clientY) {
            stepAnimate.current = 40;
            setAnimateScroll(true);
            // startAnimatitonScroll();
        } else {
            setAnimateScroll(false);
            // stopAnimatitonScroll();
        }

        if (selectionRectangle.startX < e.pageX) {
            selectionRectangle.right = e.pageX;
            selectionRectangle.left = selectionRectangle.startX;
        } else {
            selectionRectangle.left = e.pageX;
            selectionRectangle.right = selectionRectangle.startX;
        }
        if (selectionRectangle.startY < e.pageY) {
            selectionRectangle.bottom = e.pageY;
            selectionRectangle.top = selectionRectangle.startY;
        } else {
            selectionRectangle.top = e.pageY;
            selectionRectangle.bottom = selectionRectangle.startY;
        }

        showSelectionRectangle(selectionRectangle);
        selectBoxes(selectionRectangle);
    }

    function onMouseUp(e: MouseEvent) {
        console.debug('onMouseUp', isMouseDown.current, e.buttons);

        e.preventDefault();
        isMouseDown.current = false;
        setAnimateScroll(false);
        // stopAnimatitonScroll();

        hideSelectionRectangle();
        setSelectionRectangle({
            startX: 0,
            startY: 0,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
        });
    }

    function showSelectionRectangle(selection: SelectionRectangleType) {
        if (selectionRectDiv.current) {
            selectionRectDiv.current.style.left = `${selection.left}px`;
            selectionRectDiv.current.style.top = `${selection.top}px`;
            selectionRectDiv.current.style.width = `${selection.right - selection.left}px`;
            selectionRectDiv.current.style.height = `${selection.bottom - selection.top}px`;
            selectionRectDiv.current.style.display = 'block';
        }
    }

    function hideSelectionRectangle() {
        if (selectionRectDiv.current) {
            selectionRectDiv.current.style.display = 'none';
        }
    }

    function rectangleSelect() {
        const elements = new Array;

        arrNFTCardDiv.current.forEach(function(element, i) {
            if (!(
                selectionRectangle.left > element.offsetLeft + element.offsetWidth ||
                selectionRectangle.right < element.offsetLeft ||
                selectionRectangle.top > element.offsetTop + element.offsetHeight ||
                selectionRectangle.bottom < element.offsetTop
            )) {
                elements.push(i);
            }

        });
        return elements;
    }

    function selectBoxes(selection: any) {
        setBulkNFTs([]);
        const arrSelectedNFTs: BulkNFTsType[] = [];
        const arrBulkNFTsId = arrPreviousBulkNFTs.map(item => item.token_id);

        // console.debug(arrBulkNFTsId);
        // TODO: сохраненный массив bulk + текущие выделенные
        rectangleSelect().forEach(function(index) {
            // console.debug(NFTs[index])
            // const tokensIdArr = bulkNFTs.map(nft => ({tokenId: nft.token_id, id: nft.id}));

            if (!arrBulkNFTsId.includes(NFTs[index].token_id) && NFTs[index].stakingPeriod !== 90) {
                // console.debug(NFTs[index]);
                const nft: BulkNFTsType = {
                    token_id: NFTs[index].token_id,
                    id: NFTs[index].id,
                    type: NFTs[index].type
                }
                arrSelectedNFTs.push(nft);
            }
        });
        setBulkNFTs([...arrPreviousBulkNFTs, ...arrSelectedNFTs]);
    }

    return (
        <>
            {
                NFTs.length === 0
                    ? <p>There are no NFTs here</p>
                    : <>
                        {type === "Staked"
                            ? <ul className="nft__list" ref={(ref) => refListCards.current = ref}>
                                {
                                    NFTs.map((nft, index) => <li key={index} className="nft__list-item">
                                        <CardStaked {...nft as TypeStakeNFT}/>
                                    </li>)
                                }
                                <div ref={selectionRectDiv} className="selection-rect"></div>
                            </ul>
                            : <ul className="nft__list" ref={(ref) => refListCards.current = ref} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                                {
                                    NFTs.map((nft, index) => <li key={index} ref={(ref) => arrNFTCardDiv.current[index] = ref} className="nft__list-item">
                                        <CardNotStaked {...nft as TypeUnstakeNFT}/>
                                    </li>)
                                }
                                <div ref={selectionRectDiv} className="selection-rect"></div>
                            </ul>
                        }
                    </>
            }
        </>
    );
};

export default CardList;
