import {useContext} from "react";
import {StorageContext} from "providers/StorageProvider";
import {MetamaskContext} from "providers/MetamaskProvider";
import {userLogout} from "providers/UserProvider";

export const useLogoutUnauthorized = () => {
    const {clearStorage} = useContext(StorageContext);
    const {address} = useContext(MetamaskContext);

    const callback: UseLogoutUnauthorizedType = (error) => {
        try {
            if (error?.response?.data?.status === 401 && error?.response?.data?.name === "Unauthorized") {
                userLogout(address, clearStorage);
            }
        } catch (e) {
            console.log("useLogoutUnauthorized hook errors: ", e);
        }
    };

    return callback;
}

export type UseLogoutUnauthorizedType = (error: any) => void