import {ReactComponent as IconArrow} from "assets/icons/arrow.svg";
import {ReactComponent as IconBurger} from "assets/icons/burger.svg";
import {ReactComponent as IconCart} from "assets/icons/cart.svg";
import {ReactComponent as IconCheckbox} from "assets/icons/checkbox.svg";
import {ReactComponent as IconChevron} from "assets/icons/chevron.svg";
import {ReactComponent as IconEdit} from "assets/icons/edit.svg";
import {ReactComponent as IconError} from "assets/icons/error.svg";
import {ReactComponent as IconGame} from "assets/icons/game.svg";
import {ReactComponent as IconList} from "assets/icons/list.svg";
import {ReactComponent as IconLock} from "assets/icons/lock.svg";
import {ReactComponent as IconLogout} from "assets/icons/log-out.svg";
import {ReactComponent as IconLogo} from "assets/icons/logo.svg";
import {ReactComponent as IconPause} from "assets/icons/pause.svg";
import {ReactComponent as IconPlay} from "assets/icons/play.svg";
import {ReactComponent as IconProcess} from "assets/icons/process.svg";
import {ReactComponent as IconRaffles} from "assets/icons/raffles.svg";
import {ReactComponent as IconSearch} from "assets/icons/search.svg";
import {ReactComponent as IconSuccess} from "assets/icons/success.svg";
import {ReactComponent as IconUnlock} from "assets/icons/unlock.svg";
import {ReactComponent as IconValve} from "assets/icons/valve.svg";

const icons = {
    IconArrow,
    IconBurger,
    IconCart,
    IconCheckbox,
    IconChevron,
    IconEdit,
    IconError,
    IconGame,
    IconList,
    IconLock,
    IconLogout,
    IconLogo,
    IconPause,
    IconPlay,
    IconProcess,
    IconRaffles,
    IconSearch,
    IconSuccess,
    IconUnlock,
    IconValve,
};

export default icons;

export type IconsType = keyof typeof icons
