import React, {createRef, Dispatch, SetStateAction, useEffect} from "react";
import {ChildrenType} from "App";

export type ModalWrapperProps = ModalType & ChildrenType & { offFocus?: boolean }

const ModalWrapper = (
    {
        children,
        isOpen,
        closeModal,
        offFocus,
    }: ModalWrapperProps
) => {
    const modalRef = createRef<HTMLDivElement>();

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Escape") {
            closeModal(false);
        }
    };

    useEffect(() => {
        modalRef?.current?.focus();
    }, [modalRef]);

    // Do that because if focus on input without this, unfocused input, until u focused input yet.
    const focusSet = {
        ref: !offFocus ? modalRef : null,
        tabIndex: 0,
    };

    return (
        <>
            {
                isOpen
                    ? <div className="modal"{...focusSet} onKeyDown={keyDownHandler} onClick={() => closeModal(false)}>
                        {children}
                    </div>
                    : null
            }
        </>
    );
};

export default ModalWrapper;

export type ModalType = {
    isOpen: boolean
    closeModal: Dispatch<SetStateAction<boolean>> | FunctionType
}

type FunctionType = () => void
