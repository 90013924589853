import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import icons from "components/UI/icons";

type HeaderProps = {
    switchNav: Dispatch<SetStateAction<boolean>>
    status: boolean
}

const Header = ({switchNav, status}: HeaderProps) => {
    const {IconLogo, IconBurger} = icons;

    const openMenu = () => {
        switchNav(!status);
    };

    return (
        <header className="header">
            <div className="header__wrapper">
                <Link className="header__logo" to="/">
                    <IconLogo/>
                </Link>

                <div className="header__links">
                </div>

                <div className="header__right">
                    {/*<Balance v-if="user.isAuth && user.balance.unlocked !== null"/>*/}
                    {/*<Metamask/>*/}
                    <button onClick={openMenu} className="header__burger" aria-label="Open Menu">
                        <IconBurger/>
                    </button>
                    {/*<div className="header__burger">*/}
                    {/*    */}
                    {/*    <NavBurger setStatus={setIsNavOpen} status={isNavOpen}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </header>
    );
};

export default Header;
