import {MetamaskContext} from "providers/MetamaskProvider";
import {Dispatch, SetStateAction, useContext} from "react";
import {NotificationContext} from "providers/NotificationProvider";
import {ContractContext} from "providers/ContractProvider";
import {ContractTypes} from "API/AdminPanel/types";
import {BulkProcessingNFTsType, BulkStatusType, NFTsContext} from "providers/NFTsProvider";
import {ModalContext} from "providers/ModalProvider";
import {StorageContext} from "providers/StorageProvider";
import {useProcessingToStorage} from "hooks/utils/useProcessingToStorage";

export const useBulkExtendTo90Days = () => {
    const {setBulkNFTs} = useContext(NFTsContext);
    const {provider, chainIdEth, address, switchNetwork} = useContext(MetamaskContext);
    const {
        errorInsufficientFunds,
        errorUpdateStakeDay,
        errorNetwork,
        successUpdateStakeDay
    } = useContext(NotificationContext);
    const {contractStakingAddress, contractStakingABI, createContract, getAddressBalance} = useContext(ContractContext);
    const {setIsAlertModalOpen, setAlertModal} = useContext(ModalContext);
    const {storageKeyProcessingStaked} = useContext(StorageContext);
    const processingToStorage = useProcessingToStorage();

    const callback: BulkExtendTo90DaysType = async (
        bulkArr,
        type,
        {setStatus},
    ) => {
        try {
            try {
                await switchNetwork(provider, chainIdEth);
            } catch (e) {
                setAlertModal(errorNetwork);
                throw errorNetwork.text;
            }

            const processingArr = bulkArr.map(item => ({tokenId: item.tokenId, type}));
            processingToStorage(storageKeyProcessingStaked, processingArr, true);

            const {web3Provider, contract} = createContract(provider, contractStakingAddress, contractStakingABI);

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setAlertModal(errorInsufficientFunds);
                throw errorInsufficientFunds.text;
            }

            const tokenIdArr = bulkArr.map(nft => nft.tokenId);

            const overrides = {
                gasLimit: 500_000 * tokenIdArr.length,
            };

            try {
                const trx = await contract.manyUpdateStakeDay(tokenIdArr, type, overrides);
                await trx.wait();
            } catch (error) {
                setAlertModal(errorUpdateStakeDay);
                throw errorUpdateStakeDay.text;
            }

            setAlertModal(successUpdateStakeDay);
            setBulkNFTs([]);
        } catch (error) {
            console.log(error);

            const processingArr = bulkArr.map(item => ({tokenId: item.tokenId, type}));
            processingToStorage(storageKeyProcessingStaked, processingArr, false);

            setStatus(prevState => {
                return prevState.filter(processedNFT => {
                    const founded = bulkArr.find(nft => nft.tokenId === processedNFT.tokenId && nft.id === processedNFT.id);

                    if (!founded) return processedNFT;
                });
            });
        } finally {
            setIsAlertModalOpen(true);
        }
    };

    return callback;
};

export type BulkExtendTo90DaysType = (
    bulkArr: BulkProcessingNFTsType[],
    type: ContractTypes,
    events: {
        setStatus: Dispatch<SetStateAction<BulkStatusType>>
    },
) => void
