import React, {useContext, useEffect, useRef, useState} from "react";

const Cooperation = () => {
    return (
        <div className="cooperation">
            <h2 className={'cooperation__title h2'} style={{textAlign:'center'}}>In cooperation with Freebi.com</h2>

            <ul className={'cooperation__links'}>
                <li><a className={'link'} href="https://freebi.gitbook.io/wiki/freebi-staking/freebi-mining" target="_blank">Freebi Mining</a></li>
                <li><a className={'link'} href="https://freebi.gitbook.io/wiki/freebi-staking/how-to-stake-nfts" target="_blank">How to Stake NFTs</a></li>
                <li><a className={'link'} href="https://freebi.gitbook.io/wiki/freebi-staking/nft-staking-reward-allocation" target="_blank">NFT Staking Reward Allocation</a></li>
            </ul>
        </div>
    );
};

export default Cooperation;