import axios from "axios";
import {
    ADD_APPROVAL,
    GET_ALL_NFT_STAKE,
    GET_ALL_NFT_UNSTAKE,
    GET_NFT_STAKE,
    GET_NFT_UNSTAKE,
    GET_NONCE,
    GET_USER_FUND,
    IS_APPROVAL,
    LOGIN,
    SEND_FUND,
    CHECK_WITHDRAW,
} from "API/AdminPanel/url";
import {AdminPanelServicesType} from "API/AdminPanel/types";

export const AdminPanelServices: AdminPanelServicesType = {
    token: "",

    setToken(token: string) {
        this.token = token;
    },

    _createAuthConfig() {
        return {
            headers: {
                Authorization: `Bearer ${this.token}`,
                // 'Access-Control-Allow-Origin': '*',
            },
            // withCredentials: true,
            // credentials: 'include'
        }
    },

    async login(address, signature) {
        const data = {
            address,
            signature,
        };

        return await axios.post(LOGIN, data);
    },

    async getNonce(address) {
        const data = {
            address,
        };

        return await axios.post(GET_NONCE, data);
    },

    async getNFTsUnstake(page, sortName, sortContract, sortIds) {
        const data = {
            page,
            sortName,
            sortContract,
            name: sortIds,
        };

        const config = this._createAuthConfig();

        return await axios.post(GET_NFT_UNSTAKE, data, config);
    },

    async getAllNFTsUnstake(sortContract, sortIds) {
        const data = {
            sortContract,
            name: sortIds,
        };

        const config = this._createAuthConfig();

        return await axios.post(GET_ALL_NFT_UNSTAKE, data, config);
    },

    async getNFTsStake(page, sortName, sortContract, sortIds, sortIsLocked) {
        const sort = JSON.parse(sortName);

        const data = {
            page,
            ...sort,
            sortContract,
            name: sortIds,
            sortIsLocked,
        };

        const config = this._createAuthConfig();

        return await axios.post(GET_NFT_STAKE, data, config);
    },

    async getAllNFTsStake(only30Days, sortContract, sortIds, sortIsLocked) {
        const data = {
            only30Days,
            sortContract,
            name: sortIds,
            sortIsLocked,
        };

        const config = this._createAuthConfig();

        return await axios.post(GET_ALL_NFT_STAKE, data, config);
    },

    async isApproval(contract) {
        const data = {
            contract,
        };

        const config = this._createAuthConfig();

        return await axios.post(IS_APPROVAL, data, config);
    },

    async addApproval(contract) {
        const data = {
            contract,
        };

        const config = this._createAuthConfig();

        return await axios.post(ADD_APPROVAL, data, config);
    },

    async getUserFund() {
        const data = {};

        const config = this._createAuthConfig();

        return await axios.post(GET_USER_FUND, data, config);
    },

    async sendFund(address, hash, funds) {
        const data = {
            address,
            hash,
            funds,
        };

        const config = this._createAuthConfig();

        return await axios.post(SEND_FUND, data, config);
    },

    async checkWithdraw() {
        const data = {};

        const config = this._createAuthConfig();

        return await axios.post(CHECK_WITHDRAW, data, config);
    },

}