import React, {createContext, Dispatch, SetStateAction, useEffect, useState} from "react";
import {ChildrenType} from "App";
import {sha256} from "js-sha256";

const StorageProvider = ({children}: ChildrenType) => {
    const storageKeyAuthorized: StorageKeyAuthorizedType = "authorizedMetamaskHolderWallet";
    const storageKeyProcessingWithdraw: StorageKeyProcessingWithdrawType = "processingWithdraw";

    const storageKeyProcessingNotStaked: StorageKeyProcessingNotStakedType = "processingNotStaked";
    const storageKeyProcessingStaked: StorageKeyProcessingStakedType = "processingStaked";

    const [isWithdrawProcessing, setIsWithdrawProcessing] = useState(false);

    const getStorageByKey = (key: StorageKeysType) => {
        return JSON.parse(localStorage.getItem(key) as string);
    };

    const setAuthorizedTokenToStorage = (address: string, token: string) => {
        const storage = getStorageByKey(storageKeyAuthorized);

        const hashAddress = sha256(address);
        storage[hashAddress] = token;

        localStorage.setItem(storageKeyAuthorized, JSON.stringify(storage));
    };

    const setProcessingNFTsToStorage = (key: StorageProcessingNFTsKeyType, processingKey: string | string[], status: boolean) => {
        const storage = getStorageByKey(key);

        const millisecondsTo10Minutes = 600000;
        const timer = status ? Date.now() + millisecondsTo10Minutes : Date.now();

        if (Array.isArray(processingKey)) {
            processingKey.forEach((item => storage[item] = timer));
        } else {
            storage[processingKey] = timer;
        }

        localStorage.setItem(key, JSON.stringify(storage));
    };

    const setProcessingWithdraw = (address: string, hash: string, status: boolean) => {
        const storage = getStorageByKey(storageKeyProcessingWithdraw);

        const millisecondsTo10Minutes = 600000;
        const timer = status ? Date.now() + millisecondsTo10Minutes : Date.now();
        const hashAddress = sha256(address);

        storage[hashAddress] = {hash, timer};

        localStorage.setItem(storageKeyProcessingWithdraw, JSON.stringify(storage));
    };

    const clearStorage = (key: string) => {
        const storage = getStorageByKey(storageKeyAuthorized);
        delete storage[key];

        localStorage.setItem(storageKeyAuthorized, JSON.stringify(storage));
    };

    useEffect(() => {
        const storage = getStorageByKey(storageKeyAuthorized);

        if (storage == null) {
            localStorage.setItem(storageKeyAuthorized, "{}");
        }
    }, []);

    useEffect(() => {
        const storage = getStorageByKey(storageKeyProcessingWithdraw);

        if (storage == null) {
            localStorage.setItem(storageKeyProcessingWithdraw, "{}");
        }
    }, []);

    useEffect(() => {
        const storage = getStorageByKey(storageKeyProcessingNotStaked);

        if (storage == null) {
            localStorage.setItem(storageKeyProcessingNotStaked, "{}");
        }
    }, []);

    useEffect(() => {
        const storage = getStorageByKey(storageKeyProcessingStaked);

        if (storage == null) {
            localStorage.setItem(storageKeyProcessingStaked, "{}");
        }
    }, []);

    const context: StorageContextType = {
        isWithdrawProcessing,
        setIsWithdrawProcessing,

        storageKeyAuthorized,
        storageKeyProcessingNotStaked,
        storageKeyProcessingStaked,
        storageKeyProcessingWithdraw,

        setAuthorizedTokenToStorage,
        setProcessingWithdraw,
        setProcessingNFTsToStorage,

        clearStorage,
        getStorageByKey,
    };

    return (
        <StorageContext.Provider value={context}>
            {children}
        </StorageContext.Provider>
    );
};

export default StorageProvider;

export const StorageContext = createContext({} as StorageContextType);

type StorageContextType = {
    isWithdrawProcessing: boolean
    setIsWithdrawProcessing: Dispatch<SetStateAction<boolean>>

    storageKeyAuthorized: StorageKeyAuthorizedType
    storageKeyProcessingNotStaked: StorageKeyProcessingNotStakedType
    storageKeyProcessingWithdraw: StorageKeyProcessingWithdrawType
    storageKeyProcessingStaked: StorageKeyProcessingStakedType

    setProcessingWithdraw: (address: string, hash: string, status: boolean) => void
    setAuthorizedTokenToStorage: (address: string, token: string) => void
    setProcessingNFTsToStorage: (key: StorageProcessingNFTsKeyType, processingKey: string | string[], status: boolean) => void

    clearStorage: (key: string) => void

    getStorageByKey: (key: StorageKeysType) => StorageValueType
}

type StorageKeyAuthorizedType = "authorizedMetamaskHolderWallet"
type StorageKeyProcessingNotStakedType = "processingNotStaked"
type StorageKeyProcessingStakedType = "processingStaked"
type StorageKeyProcessingWithdrawType = "processingWithdraw"

export type StorageProcessingNFTsKeyType = StorageKeyProcessingNotStakedType | StorageKeyProcessingStakedType

type StorageKeysType =
    StorageKeyAuthorizedType
    | StorageKeyProcessingNotStakedType
    | StorageKeyProcessingStakedType
    | StorageKeyProcessingWithdrawType

type StorageValueType = { [key: string]: string | boolean | number | WithdrawStatusType }
export type WithdrawStatusType = { timer: number, hash: string }

