import React, {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {userLogout} from "providers/UserProvider";
import {StorageContext} from '../../providers/StorageProvider';
import {MetamaskContext} from '../../providers/MetamaskProvider';
import icons from "components/UI/icons";
import Header from './Header';

const Nav = () => {
    const {IconValve, IconCart, IconGame, IconLock, IconUnlock, IconList, IconEdit, IconRaffles, IconLogout} = icons;
    const {clearStorage} = useContext(StorageContext);
    const {address} = useContext(MetamaskContext);
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const HOST_HUB_URL = process.env.REACT_APP_HOST_HUB_URL;

    useEffect(() => {
        setIsNavOpen(false);
    }, [location]);

    return (
        <>
            <Header switchNav={setIsNavOpen} status={isNavOpen}/>

            <div className={`sidebar ${isNavOpen ? 'sidebar_opened' : ''}`}>
                <nav>
                    <ul className="nav">
                        <li className="nav__item">
                            <a href={`${HOST_HUB_URL}/shop`} className="nav__link">
                                <IconCart className="nav__svg"/>
                                <span className="nav__tooltip">Shop</span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <a href={`${HOST_HUB_URL}/play`} className="nav__link">
                                <IconGame className="nav__svg"/>
                                <span className="nav__tooltip">Play</span>
                            </a>
                        </li>
                        <li className="nav__item">
                            <Link to="/staked" className={`nav__link ${['/staked', '/not-staked'].includes(location.pathname) ? "nav__item_active" : ""}`}>
                                <IconValve className="nav__svg"/>
                                <span className="nav__tooltip">Staking</span>
                            </Link>

                            <ul className="nav">
                                <li className="nav__item">
                                    <Link to="/staked" className={`nav__link ${location.pathname === '/staked' ? "nav__item_active" : ""}`}>
                                        <span className="nav__tooltip">
                                            <IconLock className="nav__svg"/>
                                            Staked NFT
                                        </span>
                                    </Link>
                                </li>
                                <li className="nav__item">
                                    <Link to="/not-staked" className={`nav__link ${location.pathname === '/not-staked' ? "nav__item_active" : ""}`}>
                                        <span className="nav__tooltip">
                                            <IconUnlock className="nav__svg"/>
                                            Non-Staked NFT
                                        </span>
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav__item nav__item_disabled">
                            <a href={`${HOST_HUB_URL}`} className="nav__link">
                                <IconList className="nav__svg"/>
                                <span className="nav__tooltip">Intellectual Property Acquisition</span>
                            </a>
                        </li>
                        <li className="nav__item nav__item_disabled">
                            <a href={`${HOST_HUB_URL}`} className="nav__link">
                                <IconEdit className="nav__svg"/>

                                <span className="nav__tooltip">NFT Customization</span>
                            </a>
                        </li>
                        <li className="nav__item nav__item_disabled">
                            <a href={`${HOST_HUB_URL}`} className="nav__link">
                                <IconRaffles className="nav__svg"/>
                                <span className="nav__tooltip">Raffles</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="sidebar__footer">
                    <ul className="nav">
                        <li className="nav__item">
                            <span className="nav__link" onClick={() => userLogout(address, clearStorage)}>
                                <IconLogout className="nav__svg"/>
                                <span className="nav__tooltip">Logout</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </>
        // <nav className={`nav ${isNavOpen ? "open" : ""}`}>
        //     <div className="nav__wrapper">
        //         <div className="nav__top">
        //             <NavBurger setStatus={setIsNavOpen} status={isNavOpen}/>
        //         </div>
        //
        //         <div className="nav__menu">
        //             <div className="nav__menu-wrapper">
        //                 <NavList/>
        //                 <NavLogout/>
        //             </div>
        //         </div>
        //     </div>
        // </nav>
    );
};

export default Nav;
