import ImagePlaceholder from "assets/images/placeholder.png";
import ImageUnloadLight from "assets/images/unload-light.png";
import ImageUnload from "assets/images/unload.png";

const images = {
    ImagePlaceholder,
    ImageUnloadLight,
    ImageUnload,
};

export default images;

export type ImagesType = keyof typeof images