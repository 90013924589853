import React from "react";
import images from "components/UI/images";
import {LazyLoadImage} from "react-lazy-load-image-component";

type ImagePreviewType = {
    image: string
}

const ImagePreview = ({image}: ImagePreviewType) => {
    const {ImageUnload} = images;
    const imageNameSet = image.replace(/(\.[^.]+)$/, "@2x$1");

    return (
        <LazyLoadImage
            alt="preview"
            src={image}
            srcSet={`${image}, ${imageNameSet} 1.25x, ${imageNameSet} 1.5x, ${imageNameSet} 2x`}
            effect="blur"
            placeholderSrc={ImageUnload}/>
    );
};

export default ImagePreview;