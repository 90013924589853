import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {
    GetNFTsStakeResponseType,
    GetNFTsUnstakeResponseType,
    MetaType,
    TypeStakeNFT,
    TypeUnstakeNFT
} from "API/AdminPanel/types";
import {useInView} from "react-intersection-observer";
import {UserContext} from "providers/UserProvider";
import {AxiosResponse} from "axios";
import CardList from "components/NFTs/Cards/helper/CardList";
import ListSkeleton from "components/Skeleton/helper/ListSkeleton";
import {NFTsContext} from "providers/NFTsProvider";

type PanelNFTsWrapperProps<T> = {
    resetCallback: Dispatch<SetStateAction<ResetPanelCallbackType>>
    title: string
    type: PageNFTsTypes
    children: React.ReactNode
    callback: (page: number) => Promise<AxiosResponse<T>>
}

const PanelNFTsWrapper = <T extends TypeStakeNFT | TypeUnstakeNFT, K extends GetNFTsStakeResponseType | GetNFTsUnstakeResponseType>(
    {
        title,
        type,
        children,
        callback,
        resetCallback,
    }: PanelNFTsWrapperProps<K>
) => {
    const {logoutUnauthorized} = useContext(UserContext);
    const {setBulkNFTs} = useContext(NFTsContext);

    const [isLoading, setIsLoading] = useState(false);
    const [NFTs, setNFTs] = useState<T[]>([]);
    const [page, setPage] = useState(1);
    const [meta, setMeta] = useState<MetaType | null>(null);
    const [response, setResponse] = useState(false);

    const [isSkipObserver, setIsSkipObserver] = useState(false);
    const {ref, inView} = useInView({skip: isSkipObserver});

    const countNFTs = meta?.total_count ? "(" + meta.total_count + ")" : "";

    const getNFTs = async () => {
        try {
            setIsLoading(true);

            const response = await callback(page);

            const nftData = response.data.nft as T[];
            const metaData = response.data._meta as MetaType;

            setMeta(metaData);
            if (nftData) {
                setPage(page + 1);
                setNFTs(prevState => [...prevState, ...nftData]);
            } else {
                setIsSkipObserver(true);
            }
        } catch (error: any) {
            console.log(error);
            setIsSkipObserver(true);
            logoutUnauthorized(error);
        } finally {
            setIsLoading(false);
            setResponse(true);
        }
    };

    useEffect(() => {
        if (inView && !isLoading && !isSkipObserver) {
            getNFTs();
        }
    }, [inView, isLoading, isSkipObserver]);

    useEffect(() => {
        const reset = () => {
            setNFTs([]);
            setBulkNFTs([]);
            setPage(1);
            setMeta(null);
            setResponse(false);
            setIsSkipObserver(false);
        };

        resetCallback(() => reset);
    }, []);

    useEffect(() => {
        document.title = "Iggy NFTs Staking | " + title;
    }, []);

    // function onMouseDown(e: MouseEvent) {
    //     console.log('nft__container onMouseDown', e.clientX, e.clientY)
    // }

    return <div className="nft">
        <h2 className="h1 nft__title">{title} NFTs {countNFTs}</h2>

        {children}

        <div className="nft__container">
            {
                response && <CardList<T> type={type} NFTs={NFTs}/>
            }

            {
                isLoading && page + 1 === meta?.current_page && <ListSkeleton type={type}/>
            }
        </div>


        <div ref={ref} className="nft__observer"/>
    </div>;
};

export default PanelNFTsWrapper;

export type PageNFTsTypes = "Staked" | "NotStaked"

export type ResetPanelCallbackType = () => void
