import React, {createRef, useState} from "react";
import icons from "components/UI/icons";
import images from "components/UI/images";

type VideoPreviewType = {
    video: string
}

const VideoPreview = ({video}: VideoPreviewType) => {
    const {ImageUnload} = images;
    const {IconPlay, IconPause} = icons;
    const [isPlay, setIsPlay] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = createRef<HTMLVideoElement>();

    const playVideo = () => {
        if (!isPlay) {
            videoRef.current?.play();
            setIsPlay(true);
        } else {
            videoRef.current?.pause();
            setIsPlay(false);
        }
    };

    return (
        <div className="preview__video-wrapper">
            {
                isLoading && <img className="preview__video-placeholder" src={ImageUnload} alt="placeholder"/>
            }

            <video width={255} height={255} ref={videoRef} onLoadedData={
                () => setIsLoading(false)
            } autoPlay={false} loop={true} muted={true}>
                <source src={video} type="video/mp4"/>
            </video>

            {
                !isLoading && <button onClick={playVideo} className="preview__btn-play" aria-label="Play">
                    {
                        !isPlay ? <IconPlay/> : <IconPause/>
                    }
                </button>
            }
        </div>
    );
};

export default VideoPreview;