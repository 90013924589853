import {Dispatch, SetStateAction, useContext} from "react";
import {MetamaskContext} from "providers/MetamaskProvider";
import {ContractContext} from "providers/ContractProvider";
import {NotificationContext} from "providers/NotificationProvider";
import {UserContext} from "providers/UserProvider";
import {StakingDaysType} from "hooks/useStaking";
import {ModalContext} from "providers/ModalProvider";
import {ContractTypes} from "API/AdminPanel/types";
import {AdminPanelServices} from "API/AdminPanel";
import {BulkNFTsType, BulkProcessingNFTsType, BulkStatusType} from "providers/NFTsProvider";
import {StorageContext} from "providers/StorageProvider";
import {useProcessingToStorage} from "hooks/utils/useProcessingToStorage";

export const useBulkStaking = () => {
    const {provider, chainIdEth, address, switchNetwork} = useContext(MetamaskContext);
    const {
        errorInsufficientFunds,
        errorApprovalForAll,
        errorStaking,
        errorNetwork,
        successStaking
    } = useContext(NotificationContext);
    const {setIsAlertModalOpen, setAlertModal} = useContext(ModalContext);
    const {
        contractStakingABI,
        contractStakingAddress,
        contractLadyAddress,
        contractBoyAddress,
        contractBoyABI,
        contractLadyABI,
        createContract,
        getAddressBalance,
    } = useContext(ContractContext);
    const {logoutUnauthorized} = useContext(UserContext);
    const {storageKeyProcessingNotStaked} = useContext(StorageContext);
    const processingToStorage = useProcessingToStorage();

    const callback: AddToBulkStakingType = async (
        bulkArr,
        type,
        stakingDays,
        {setStatus, setBulkNFTs},
    ) => {
        try {
            try {
                await switchNetwork(provider, chainIdEth);
            } catch (e) {
                setAlertModal(errorNetwork);
                throw errorNetwork.text;
            }

            const processingArr = bulkArr.map(item => ({tokenId: item.tokenId, type}));
            processingToStorage(storageKeyProcessingNotStaked, processingArr, true);

            const {
                web3Provider,
                contract: contractStaking
            } = createContract(provider, contractStakingAddress, contractStakingABI);

            const {contract: contractIggyBoy} = createContract(provider, contractBoyAddress, contractBoyABI);
            const {contract: contractIggyLady} = createContract(provider, contractLadyAddress, contractLadyABI);

            const contracts = [
                {contract: contractIggyBoy, contractAddress: contractBoyAddress},
                {contract: contractIggyLady, contractAddress: contractLadyAddress},
            ];
            const contract = contracts[type].contract;
            const contractAddress = contracts[type].contractAddress;

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setAlertModal(errorInsufficientFunds);
                throw errorInsufficientFunds.text;
            }

            const responseApproval = await AdminPanelServices.isApproval(contractAddress);
            const isApproval = responseApproval.data.approval;

            if (!isApproval) {
                try {
                    const trxApproval = await contract.setApprovalForAll(contractStakingAddress, true);
                    await trxApproval.wait();
                    await AdminPanelServices.addApproval(contractAddress);
                } catch (error) {
                    setAlertModal(errorApprovalForAll);
                    logoutUnauthorized(error);
                    throw errorApprovalForAll.text;
                }
            }

            const tokenIdArr = bulkArr.map(nft => nft.tokenId);

            const overrides = {
                gasLimit: 500_000 * tokenIdArr.length,
            };

            try {
                const trxStake = await contractStaking.manyStaking(tokenIdArr, type, stakingDays, overrides);
                setBulkNFTs([]);
                await trxStake.wait();

            } catch (error) {
                console.log(error);
                setAlertModal(errorStaking);
                throw errorStaking.text;
            }

            setAlertModal(successStaking);
        } catch (error) {
            console.log(error);

            const processingArr = bulkArr.map(item => ({tokenId: item.tokenId, type}));
            processingToStorage(storageKeyProcessingNotStaked, processingArr, false);

            setStatus(prevState => {
                return prevState.filter(processedNFT => {
                    const founded = bulkArr.find(nft => nft.tokenId === processedNFT.tokenId && nft.id === processedNFT.id);

                    if (!founded) return processedNFT;
                });
            });

            logoutUnauthorized(error);
        } finally {
            setIsAlertModalOpen(true);
        }
    };

    return callback;
};

export type AddToBulkStakingType = (
    bulkArr: BulkProcessingNFTsType[],
    type: ContractTypes,
    stakingDays: StakingDaysType,
    events: {
        setStatus: Dispatch<SetStateAction<BulkStatusType>>
        setBulkNFTs: Dispatch<SetStateAction<BulkNFTsType[]>>
    },
) => void
