import React, {ChangeEvent} from "react";
import icons from "components/UI/icons";

type InputCheckboxProps = {
    isChecked: boolean
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    readOnly?: boolean
}

const InputCheckbox = ({isChecked, onChange = () => {}, readOnly}: InputCheckboxProps) => {
    const {IconCheckbox} = icons;
    const status = isChecked ? "checked" : "";

    return (
        <label className={`checkbox ${status}`}>
            <input checked={isChecked} disabled={readOnly} onChange={onChange} type="checkbox"/>
            <span className={`checkbox__indicator ${status}`}>
                <IconCheckbox/>
            </span>
        </label>
    );
};

export default InputCheckbox;