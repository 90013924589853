import React, {InputHTMLAttributes} from "react";

export type InputProps = InputHTMLAttributes<HTMLInputElement>

const Input = (props: InputProps) => {
    return (
        <input {...props} className={`input ${props?.className ? props.className : ""}`}/>
    );
};

export default Input;