import {MetamaskContext} from "providers/MetamaskProvider";
import {useContext} from "react";
import {NotificationContext} from "providers/NotificationProvider";
import {ContractContext} from "providers/ContractProvider";
import {EventsHookType} from "hooks/useStaking";
import {StorageContext} from "providers/StorageProvider";
import {useProcessingToStorage} from "hooks/utils/useProcessingToStorage";

export const useUnstaking = () => {
    const {provider, chainIdEth, address, switchNetwork} = useContext(MetamaskContext);
    const {errorInsufficientFunds, errorUnstaking, errorNetwork, successUnstaking} = useContext(NotificationContext);
    const {contractStakingAddress, contractStakingABI, createContract, getAddressBalance} = useContext(ContractContext);
    const {storageKeyProcessingStaked} = useContext(StorageContext);
    const processingToStorage = useProcessingToStorage();

    const callback: AddToUnstakingType = async (
        tokenId,
        id,
        type,
        index_array,
        {setIsProcessing, setNotificationInfo, setIsNotificationOpen, setBulkNFTsProcessing},
    ) => {
        try {
            setIsProcessing(true);

            try {
                await switchNetwork(provider, chainIdEth);
            } catch (e) {
                setNotificationInfo(errorNetwork);
                throw errorNetwork.text;
            }

            processingToStorage(storageKeyProcessingStaked, {tokenId, type}, true);

            const {web3Provider, contract} = createContract(provider, contractStakingAddress, contractStakingABI);

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setNotificationInfo(errorInsufficientFunds);
                throw errorInsufficientFunds.text;
            }

            const overrides = {
                gasLimit: 1_000_000,
            };

            try {
                const trx = await contract.unstake(tokenId, type, index_array, overrides);
                await trx.wait();
            } catch (error) {
                setNotificationInfo(errorUnstaking);
                throw errorUnstaking.text;
            }

            setNotificationInfo(successUnstaking);
        } catch (error) {
            console.log(error);

            processingToStorage(storageKeyProcessingStaked, {tokenId, type}, false);

            setBulkNFTsProcessing(prevState => {
                return prevState.filter(processedNFT => processedNFT.tokenId !== tokenId && processedNFT.id !== id);
            });

            setIsProcessing(false);
        } finally {
            setIsNotificationOpen(true);
        }
    };

    return callback;
}

export type AddToUnstakingType = (
    tokenId: string,
    id: number,
    type: number,
    index_array: number,
    events: EventsHookType,
) => void