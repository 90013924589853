import React, {useContext, useEffect, useState} from "react";
import BulkWrapper from "components/Bulk/helper/BulkWrapper";
import {BulkNFTsType, NFTsContext} from "providers/NFTsProvider";
import {UserContext} from "providers/UserProvider";
import {GetAllNFTsResponseType} from "API/AdminPanel/types";
import {AxiosResponse} from "axios";
import {StorageContext, StorageProcessingNFTsKeyType} from "providers/StorageProvider";

type BulkProps = {
    storageKey: StorageProcessingNFTsKeyType
    callback: () => Promise<AxiosResponse<GetAllNFTsResponseType, any>>
    dependencies: (string | number)[]
    children: React.ReactNode
}

const Bulk = ({children, callback, dependencies, storageKey}: BulkProps) => {
    const {logoutUnauthorized} = useContext(UserContext);
    const {bulkNFTsProcessing, setBulkNFTsProcessing, bulkNFTs, setIsAllNFTsLoading} = useContext(NFTsContext);
    const {getStorageByKey} = useContext(StorageContext);

    const [NFTs, setNFTs] = useState<BulkNFTsType[]>([]);
    const [filteredNFTs, setFilteredNFTs] = useState<BulkNFTsType[]>([]);

    const getAllNFTs = async () => {
        try {
            const response = await callback();

            if (response.data.nft) {
                setNFTs(response.data.nft);
            } else {
                setNFTs([]);
            }
        } catch (error: any) {
            console.log(error);
            logoutUnauthorized(error);
        }
    };

    useEffect(() => {
        getAllNFTs();
    }, dependencies);

    // Check processing status from local storage for all nft - COMPLETED
    useEffect(() => {
        NFTs.forEach(nft => {
            const key = nft.token_id + "_" + nft.type;
            const storage = getStorageByKey(storageKey);
            const itemTimer = storage[key] as number;

            if (itemTimer !== undefined && itemTimer > Date.now()) {
                setBulkNFTsProcessing(prevState => [...prevState, {tokenId: nft.token_id, id: nft.id}]);
            }
        })
    }, [NFTs]);

    // Filtered processing nft for all not selected nft - COMPLETED
    useEffect(() => {
        const filtered = NFTs.filter(nft => {
            const founded = bulkNFTsProcessing.find(processedNFT => nft.token_id === processedNFT.tokenId && nft.id == processedNFT.id);
            if (!founded) return nft;
        });

        setIsAllNFTsLoading(filtered.length === 0);
        setFilteredNFTs(filtered);
    }, [bulkNFTsProcessing, NFTs]);

    return (
        <BulkWrapper NFTs={filteredNFTs}>
            {children}
        </BulkWrapper>
    );
};

export default Bulk;