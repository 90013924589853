import React, {useContext, useEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {UserContext} from "providers/UserProvider";
import Login from "pages/Login";
import Staked from "pages/Staked";
import NotStaked from "pages/NotStaked";

const Router = () => {
    const {isAuthorized} = useContext(UserContext);
    const navigate = useNavigate(); // хук для программного перенаправления
    const location = useLocation();
    const [firstPage, setFistPage] = useState<string|null>(null);

    useEffect(() => {
        // console.log('useEffect []', isAuthorized, location.pathname);
        if (location.pathname !== '/login') {
            setFistPage(location.pathname);
        }
    }, []);

    useEffect(() => {
        // console.log('useEffect', isAuthorized, location.pathname);
        if (isAuthorized && location.pathname === '/login' && firstPage) {
            navigate(firstPage, { replace: true });
            setFistPage(null);
        } else if (isAuthorized && location.pathname === '/') {
            navigate('/staked', { replace: true });
        } else if (!isAuthorized && location.pathname === '/') {
            navigate('/login', { replace: true });
        }
    }, [isAuthorized, location, navigate]);

    return (
        <Routes>
            <Route path="/" element={<></>} />
            {isAuthorized ? (
                <>
                    <Route path="staked" element={<Staked />} />
                    <Route path="not-staked" element={<NotStaked />} />
                </>
            ) : (
                <Route path="login" element={<Login />} />
            )}
            <Route path="*" element={<Navigate to={isAuthorized ? '/staked' : '/login'} replace />} />
        </Routes>
    );
};

export default Router;
