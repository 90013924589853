import React, {useContext, useEffect, useState} from "react";
import InputCheckbox from "components/UI/Inputs/InputCheckbox";
import {BulkNFTsType, NFTsContext} from "providers/NFTsProvider";
import {ChildrenType} from "App";

type BulkProps = {
    NFTs: BulkNFTsType[]
} & ChildrenType

const BulkWrapper = (
    {
        NFTs,
        children,
    }: BulkProps
) => {
    const [isSelected, setIsSelected] = useState(false);
    const {setBulkNFTs, bulkNFTs, isAllNFTsLoading} = useContext(NFTsContext);

    // select all nft - COMPLETED
    const handleSelectAll = () => {
        setIsSelected(!isSelected);
        !isSelected ? setBulkNFTs(NFTs) : setBulkNFTs([]);
    };

    // if all nft selected button is selected - COMPLETED
    useEffect(() => {
        if (bulkNFTs.length !== 0) {
            NFTs.length === bulkNFTs.length ? setIsSelected(true) : setIsSelected(false);
        } else {
            setIsSelected(false);
        }
    }, [bulkNFTs, NFTs]);

    return (
        <div className="nft__tools">
            <div className="nft__tools-col">
                <button
                    disabled={isAllNFTsLoading}
                    onClick={handleSelectAll}
                    className={`button button-checkbox button--tertiary ${isSelected ? "checked" : ""} nft__filter-buttons-only-one`}
                >
                    <InputCheckbox readOnly isChecked={isSelected}/>
                    <span className="button-checkbox__text">Select All</span>
                </button>
                {
                    bulkNFTs.length ?
                        <>
                            <div className="nft__selected">Selected: <span>{bulkNFTs.length}</span>
                                <div className="nft__clear" onClick={() => setBulkNFTs([])}>
                                    <svg viewBox="0 0 32 32" width={22} height={22} xmlns="http://www.w3.org/2000/svg"><g fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"><path d="m7 7 18 18"/><path d="m7 25 18-18"/></g></svg>
                                    Clear
                                </div>
                            </div>

                        </> : ''
                }
            </div>

            <div className="nft__tools-col">
                {
                    bulkNFTs.length !== 0 && children
                }
            </div>
        </div>
    );
};

export default BulkWrapper;
