import React, {useContext} from "react";
import {NFTsContext} from "providers/NFTsProvider";
import Bulk from "components/Bulk/helper/Bulk";
import {AdminPanelServices} from "API/AdminPanel";
import {TransactionContext} from "providers/TransactionProvider";
import {StorageContext} from "providers/StorageProvider";

type BulkExtendTo90DaysProps = {
    byIds: string
    byStatus: string
    byCollection: number
}

const BulkExtendTo90Days = ({byCollection, byStatus, byIds}: BulkExtendTo90DaysProps) => {
    const {bulkNFTs, setBulkNFTsProcessing} = useContext(NFTsContext);
    const {bulkExtendTo90Days} = useContext(TransactionContext);
    const {storageKeyProcessingStaked} = useContext(StorageContext);

    const extendTo90Days = async () => {
        const events = {setStatus: setBulkNFTsProcessing};
        console.log('extendTo90Days')

        const tokensIdArr = bulkNFTs.map(nft => ({tokenId: nft.token_id, id: nft.id}));

        setBulkNFTsProcessing(prevState => ([...prevState, ...tokensIdArr]));

        bulkExtendTo90Days(tokensIdArr, bulkNFTs[0].type, events);
    };

    const callback = async () => {
        return await AdminPanelServices.getAllNFTsStake(true, byCollection, byIds, byStatus);
    };

    return (
        <Bulk storageKey={storageKeyProcessingStaked} callback={callback} dependencies={[byCollection, byIds, byStatus]}>
            <button onClick={extendTo90Days} className="button button--primary">
                Extend to 90 days
            </button>
        </Bulk>
    );
};

export default BulkExtendTo90Days;
