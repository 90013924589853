import React from "react";
import ImagePreview from "components/Preview/helper/ImagePreview";
import VideoPreview from "components/Preview/helper/VideoPreview";
import images from "components/UI/images";

type PreviewType = {
    image: string
}

const Preview = ({image}: PreviewType) => {
    const {ImageUnload} = images;

    return (
        <div className="preview">
            {
                image
                    ? <>
                        {
                            image.endsWith(".jpg") && <ImagePreview image={image}/>
                        }

                        {
                            image.endsWith(".mp4") && <VideoPreview video={image}/>
                        }
                    </>
                    : <img src={ImageUnload} alt=""/>
            }
        </div>
    );
};

export default Preview;
