import React, {useContext, useEffect, useRef, useState} from "react";
import {AdminPanelServices} from "API/AdminPanel";
import {UserContext} from "providers/UserProvider";
import {ModalContext} from "providers/ModalProvider";
import {StorageContext} from "providers/StorageProvider";
import Coin from "components/UI/Coins/Coin";
import Loader from "components/UI/Loader/Loader";
import ModalDeposit from "components/Modals/Confirm/ModalDeposit";
import ModalWithdraw from "components/Modals/Confirm/ModalWithdraw";

const Balance = () => {
    const intervalCheckWithdraw = 60000;
    const {userFund, setUserFund, logoutUnauthorized} = useContext(UserContext);
    const {setIsDepositModalOpen, setIsWithdrawModalOpen} = useContext(ModalContext);
    const {isWithdrawProcessing, setIsWithdrawProcessing} = useContext(StorageContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const timer = useRef<number|undefined>();

    const onOpenModalWithdraw = () => {
        setIsWithdrawModalOpen(true);
    };

    const onOpenModalDeposit = () => {
        setIsDepositModalOpen(true);
    };

    const getUserFunds = async () => {
        try {
            setIsLoading(true);
            const response = await AdminPanelServices.getUserFund();
            setUserFund(response.data);
        } catch (error) {
            console.log(error);
            logoutUnauthorized(error);
        } finally {
            setIsLoading(false);
        }
    };

    const checkWithdraw = async () => {
        try {
            const response = await AdminPanelServices.checkWithdraw();

            if (response?.data) {
                if (response.data.withdrawProcessing && timer.current === undefined) {
                    timer.current = setInterval(handlerTimer => {
                        checkWithdraw();
                    }, intervalCheckWithdraw)
                } else if (!response.data.withdrawProcessing && timer.current !== undefined) {
                    clearInterval(timer.current);
                    timer.current = undefined;
                }

                setIsWithdrawProcessing(response.data.withdrawProcessing);

                if (!response.data.withdrawProcessing) {
                    getUserFunds();
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // console.log('useEffect()', isWithdrawProcessing, timer.current)
        getUserFunds();
        checkWithdraw();
    }, []);

    useEffect(() => {
        // console.log('useEffect([isWithdrawProcessing])', isWithdrawProcessing, timer.current)
        if (isWithdrawProcessing && !timer.current) {
            timer.current = setInterval(async handlerTimer => {
                await checkWithdraw();
            }, intervalCheckWithdraw)
        }
    }, [isWithdrawProcessing]);

    return (
        <>
            <div className="balance">
                <div className="balance__wrapper">
                    <h2 className="balance__title h1">Balance</h2>
                    {
                        userFund && !isLoading ?
                            <>
                                <ul className="balance__list">

                                    <li className="balance__list-item">
                                        <Coin icon="IconSuccess" type="unlocked" counter={userFund.unlocked}
                                              description="$FRBI unlocked"/>
                                    </li>

                                    <li className="balance__list-item">
                                        <Coin icon="IconProcess" type="locked" counter={userFund.locked}
                                              description="$FRBI locked"/>
                                    </li>
                                </ul>

                                <div className="balance__buttons">
                                    <button disabled={isProcessing} onClick={onOpenModalDeposit}
                                            className="button button--primary">
                                        Deposit Coins
                                    </button>

                                    <button disabled={isWithdrawProcessing || isProcessing || userFund.unlocked <= 0} onClick={onOpenModalWithdraw}
                                            className="button balance__btn-withdraw button--tertiary">
                                        Withdraw Coins
                                    </button>
                                </div>
                            </> : <Loader/>
                    }
                </div>
            </div>

            <ModalDeposit setIsProcessing={setIsProcessing}/>
            <ModalWithdraw setIsProcessing={setIsProcessing}/>
        </>
    );
};

export default Balance;