import React, {Dispatch, SetStateAction} from "react";
import icons from "components/UI/icons";
import ModalAlertWrapper from "components/Modals/helper/ModalAlertWrapper";
import {AlertIconType, AlertModalType, VariantTypeValue} from "components/Modals/Alert/ModalAlert";

export type AlertModalNotificationType = {
    isOpen: boolean
    setIsOpen: Dispatch<SetStateAction<boolean>>
} & AlertModalType

const ModalAlertNotification = ({variant, title, text, isOpen, setIsOpen}: AlertModalNotificationType) => {
    const createIcon = (variant: VariantTypeValue): AlertIconType => {
        return "Icon" + variant as AlertIconType;
    };

    const iconName = createIcon(variant);
    const Icon = icons[iconName];

    return (
        <ModalAlertWrapper isOpen={isOpen} closeModal={setIsOpen}>
            <div onClick={
                (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
            } className="modal__wrapper modal__alert">
                <button onClick={() => setIsOpen(false)} className="modal__btn-close">close</button>

                <div className="modal__inner">
                    <div className={`modal__icon icon icon--small icon--${variant.toLowerCase()}`}>
                        <Icon/>
                    </div>

                    <div className="modal__alert-info">
                        <h3 className={`h2 modal__alert-title modal__alert-title--${variant.toLowerCase()}`}>{title}</h3>
                        <p className="modal__alert-text">{text}</p>
                    </div>
                </div>
            </div>
        </ModalAlertWrapper>
    );
};

export default ModalAlertNotification;

