import {Dispatch, SetStateAction, useContext, useEffect} from "react";
import {BulkNFTsType, BulkStatusType, NFTsContext} from "providers/NFTsProvider";
import {AlertModalType} from "components/Modals/Alert/ModalAlert";

export const useBulkSelect: UseBulkSelectType = (
    data,
    events,
) => {
    const {bulkNFTsProcessing} = useContext(NFTsContext);
    const {
        isChecked,
        setIsChecked,
        setProcessingInfoText,
        processingInfoText,
        bulkNFTs,
        setBulkNFTs,
        setIsProcessing,
        isProcessing,
    } = events;

    // Select Card and add or remove card from Bulk Array - COMPLETED
    const handleSelect = () => {
        console.log('handleSelect')
        if (!isChecked) {
            setBulkNFTs(prevState => [...prevState, data]);
        } else {
            setBulkNFTs(prevState => prevState.filter(item => item.token_id !== data.token_id && item.id !== data.id));
        }
    };

    // Check if Card is in Bulk Array and change checked status if true - COMPLETED
    useEffect(() => {
        const selected = bulkNFTs.find(item => item.token_id === data.token_id && item.id === data.id);

        setIsChecked(!!selected);
    }, [bulkNFTs]);

    // Check if Card is in Bulk Processing Array and change processing status if true - COMPLETED
    useEffect(() => {
        const foundProcessing = bulkNFTsProcessing.find(nft => nft.tokenId === data.token_id && nft.id === data.id);

        setIsProcessing(!!foundProcessing);
    }, [bulkNFTsProcessing]);

    // Check if Card is Processing and change processing text if true - COMPLETED
    useEffect(() => {
        if (isProcessing) setProcessingInfoText(processingInfoText);
    }, [isProcessing]);

    return {handleSelect};
};

type UseBulkSelectType = (
    data: BulkNFTsType,
    events: BulkEventsType,
) => { handleSelect: () => void }

export type BulkEventsType = {
    isChecked: boolean
    setIsChecked: Dispatch<SetStateAction<boolean>>

    isProcessing: boolean
    setIsProcessing: Dispatch<SetStateAction<boolean>>

    isNotificationOpen: boolean
    setIsNotificationOpen: Dispatch<SetStateAction<boolean>>

    notificationInfo: AlertModalType
    setNotificationInfo: Dispatch<SetStateAction<AlertModalType>>

    processingInfoText: string
    setProcessingInfoText: Dispatch<SetStateAction<string>>

    bulkNFTs: BulkNFTsType[]
    setBulkNFTs: Dispatch<SetStateAction<BulkNFTsType[]>>

    setBulkNFTsProcessing: Dispatch<SetStateAction<BulkStatusType>>
}
