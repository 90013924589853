import React, {Dispatch, SetStateAction} from "react";
import {SelectOptionType} from "components/UI/Inputs/InputSelect";

type SortByStatusProps = {
    callback: () => void;
    selected: SelectOptionType
    setSelected: Dispatch<SetStateAction<SelectOptionType>>
    options: SelectOptionType[]
}

const SortByStatus = ({options, selected, callback, setSelected}: SortByStatusProps) => {
    const handleChange = (option: SelectOptionType) => {
        callback();
        setSelected(option);
    };

    return (
        <div className="sort-tabs">
            {
                options.map((option, index) => (
                        <button key={index} onClick={() => handleChange(option)}
                                className={`sort-tabs__btn ${selected.label === option.label ? "active" : ""}`}>
                            {option.label}
                        </button>
                    )
                )
            }
        </div>
    );
};

export default SortByStatus;

