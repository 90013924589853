import {StorageContext, StorageProcessingNFTsKeyType} from "providers/StorageProvider";
import {useContext} from "react";

export const useProcessingToStorage = () => {
    const {setProcessingNFTsToStorage} = useContext(StorageContext);

    const callback: UseProcessingToStorage = (key, nftKey, status) => {
        let storageKey;

        if (Array.isArray(nftKey)) {
            storageKey = nftKey.map(nft => nft.tokenId + "_" + nft.type)
        } else {
            storageKey = nftKey.tokenId + "_" + nftKey.type;
        }

        setProcessingNFTsToStorage(key, storageKey, status);
    };

    return callback;
};

export type UseProcessingToStorage = (key: StorageProcessingNFTsKeyType, nftKey: NFTKeyType | NFTKeyType[], status: boolean) => void;
type NFTKeyType = { tokenId: string, type: number }