import React from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {BrowserRouter} from "react-router-dom";
import UserProvider from "providers/UserProvider";
import MetamaskProvider from "providers/MetamaskProvider";
import ModalProvider from "providers/ModalProvider";
import ContractProvider from "providers/ContractProvider";
import NotificationProvider from "providers/NotificationProvider";
import TransactionProvider from "providers/TransactionProvider";
import StorageProvider from "providers/StorageProvider";

Sentry.init({
    dsn: "https://7c98806186d0efa06ddf64a4fb8de4d6@ire1-sentry1-api.1gamehub.com/6",
    integrations: [new BrowserTracing()],
    environment: process.env.APP_ENV ?? 'development',

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
        <NotificationProvider>
            <ModalProvider>
                <StorageProvider>
                    <UserProvider>
                        <ContractProvider>
                            <MetamaskProvider>
                                <TransactionProvider>
                                    <App/>
                                </TransactionProvider>
                            </MetamaskProvider>
                        </ContractProvider>
                    </UserProvider>
                </StorageProvider>
            </ModalProvider>
        </NotificationProvider>
    </BrowserRouter>
);