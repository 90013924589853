import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import ModalAlert, {AlertModalType} from "components/Modals/Alert/ModalAlert";
import {ChildrenType} from "App";

const ModalProvider = ({children}: ChildrenType) => {
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
    const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

    const [alertModal, setAlertModal] = useState<AlertModalType>({text: "", title: "", variant: "Error", link: null});

    const context: ModalContextType = {
        alertModal,
        setAlertModal,

        isAlertModalOpen,
        setIsAlertModalOpen,

        isDepositModalOpen,
        setIsDepositModalOpen,

        isWithdrawModalOpen,
        setIsWithdrawModalOpen,
    };

    return (
        <ModalContext.Provider value={context}>
            {children}

            <ModalAlert/>
        </ModalContext.Provider>
    );
};

export default ModalProvider;

export const ModalContext = createContext({} as ModalContextType);

type ModalContextType = {
    alertModal: AlertModalType
    setAlertModal: Dispatch<SetStateAction<AlertModalType>>

    isAlertModalOpen: boolean
    setIsAlertModalOpen: Dispatch<SetStateAction<boolean>>

    isDepositModalOpen: boolean
    setIsDepositModalOpen: Dispatch<SetStateAction<boolean>>

    isWithdrawModalOpen: boolean
    setIsWithdrawModalOpen: Dispatch<SetStateAction<boolean>>
}

export type ModalOpenStatus = { isOpen: boolean, setIsOpen: Dispatch<SetStateAction<boolean>> }

