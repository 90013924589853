import React from "react";
import Skeleton from "react-loading-skeleton";
import SkeletonWrapper from "components/Skeleton/helper/SkeletonWrapper";

const CardStakedSkeleton = () => {
    return (
        <SkeletonWrapper>
            <ul className="card-nft__info-list">
                <Skeleton height={22} borderRadius={8}/>

                <Skeleton height={22} borderRadius={8}/>

                <Skeleton height={22} borderRadius={8}/>

                <Skeleton height={22} borderRadius={8}/>
            </ul>

            <div className="card-nft__buttons">
                <Skeleton height={40} borderRadius={8}/>

                <Skeleton height={40} borderRadius={8}/>
            </div>
        </SkeletonWrapper>
    );
};

export default CardStakedSkeleton;