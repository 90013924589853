import React, {useContext} from "react";
import {StakingDaysType} from "hooks/useStaking";
import {NFTsContext} from "providers/NFTsProvider";
import Bulk from "components/Bulk/helper/Bulk";
import {AdminPanelServices} from "API/AdminPanel";
import {TransactionContext} from "providers/TransactionProvider";
import {StorageContext} from "providers/StorageProvider";

type BulkStakingProps = {
    byCollection: number
    byIds: string
}

const BulkStaking = ({byCollection, byIds}: BulkStakingProps) => {
    const {addToBulkStaking} = useContext(TransactionContext);
    const {bulkNFTs, setBulkNFTs, setBulkNFTsProcessing} = useContext(NFTsContext);
    const {storageKeyProcessingNotStaked} = useContext(StorageContext);

    const staking = async (days: StakingDaysType) => {
        const events = {
            setStatus: setBulkNFTsProcessing,
            setBulkNFTs: setBulkNFTs
        };

        // setBulkNFTs([]);

        const tokensIdArr = bulkNFTs.map(nft => ({tokenId: nft.token_id, id: nft.id}));

        setBulkNFTsProcessing(prevState => ([...prevState, ...tokensIdArr]));

        await addToBulkStaking(tokensIdArr, bulkNFTs[0].type, days, events);
    };

    const callback = async () => {
        return await AdminPanelServices.getAllNFTsUnstake(byCollection, byIds);
    };

    return (
        <Bulk storageKey={storageKeyProcessingNotStaked} callback={callback} dependencies={[byCollection, byIds]}>
            <button onClick={() => staking(90)} className="button button--primary">
                Stake for 90 days
            </button>

            <button onClick={() => staking(30)} className="button button--secondary">
                Stake for 30 days
            </button>
        </Bulk>
    );
};

export default BulkStaking;
