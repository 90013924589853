import React from "react";
import ProcessingLoader from "components/UI/Loader/ProcessingLoader";

type ProcessInfoType = {
    text: string
}

const ProcessInfo = ({text}: ProcessInfoType) => {
    return (
        <div className="card-nft__process">
            <div className="card-nft__process-header">
                <h5 className="card-nft__process-title h2">Processing</h5>
                <ProcessingLoader/>
            </div>

            <p>{text}</p>
        </div>
    );
};

export default ProcessInfo;