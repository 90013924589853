import React, {useContext} from "react";
import "assets/styles/global.scss";
import {UserContext} from "providers/UserProvider";
import Router from "routers/Router";
import ScreenLoader from "components/UI/Loader/ScreenLoader";

const App = () => {
    const {isLoginLoaded} = useContext(UserContext);

    return (
        <div className="App">
            <Router/>

            {
                isLoginLoaded ? <ScreenLoader/> : null
            }
        </div>
    );
};

export default App;

export type ChildrenType = {
    children: JSX.Element | React.ReactNode
}