import React, {useState} from "react";
import {generateSkeletonList} from "components/Skeleton/helper/SkeletonWrapper";
import CardStakedSkeleton from "components/Skeleton/CardStakedSkeleton";

type ListSkeletonProps = {
    type: "Staked" | "NotStaked"
}

const ListSkeleton = ({type}: ListSkeletonProps) => {
    const [skeletonGenerated] = useState(generateSkeletonList(6));

    return (
        <ul className="nft__list">
            {
                skeletonGenerated.map(skeleton => <li key={skeleton} className="nft__list-item">
                    {
                        type === "Staked" && <CardStakedSkeleton/>
                    }
                    {
                        type === "NotStaked" && <CardStakedSkeleton/>
                    }
                </li>)
            }
        </ul>
    );

};

export default ListSkeleton;