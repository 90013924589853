import {ProviderType} from "hooks/utils/useProvider";
import {useContext} from "react";
import {ModalContext} from "providers/ModalProvider";

export const useImportToken = () => {
    const {setAlertModal} = useContext(ModalContext);

    const callback: UseImportTokenCallbackType = async (
        provider,
        type,
        address,
        symbol,
        decimals
    ) => {
        if (!provider) return;

        try {
            await provider.request({
                method: 'wallet_watchAsset',
                params: {
                    type,
                    options: {
                        address,
                        symbol,
                        decimals,
                    },
                },
            });
        } catch (error: any) {
            console.log(error);

            setAlertModal({
                title: "Error Import Token Metamask!",
                text: error.message,
                variant: "Error",
                link: null,
            });

            throw error.message;
        }
    };

    return callback;
};

export type UseImportTokenCallbackType = (provider: ProviderType, type: string, address: string, symbol: string, decimals: number) => void