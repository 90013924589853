import React from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {ChildrenType} from "App";
import {useResize} from "hooks/utils/useResize";

const SkeletonWrapper = ({children}: ChildrenType) => {
    const {ref, size} = useResize();

    return (
        <div className="card-nft">
            <SkeletonTheme baseColor="#15181f" highlightColor="#363940">

                <div style={{height: size}} ref={ref} className="card-nft__preview">
                    <Skeleton height={size} borderRadius={16}/>
                </div>

                <h3 className="card-nft__name h2">
                    <Skeleton height={24} borderRadius={8}/>
                </h3>

                {children}
            </SkeletonTheme>
        </div>
    );
};

export default SkeletonWrapper;

export const generateSkeletonList: generateSkeletonListType = (limit: number) => {
    const list = [];

    for (let i = 0; i < limit; i++) {
        list.push(i);
    }

    return list;
};

type generateSkeletonListType = (limit: number) => number[]