import React, {createContext, Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ProviderType, useProvider} from "hooks/utils/useProvider";
import {ChildrenType} from "App";
import {UserContext} from "providers/UserProvider";
import {sha256} from "js-sha256";
import {StorageContext} from "providers/StorageProvider";
import {AddNetworkCallbackType, SwitchNetworkCallbackType, useNetwork} from "hooks/utils/useNetwork";
import {AdminPanelServices} from "API/AdminPanel";
import {useImportToken, UseImportTokenCallbackType} from "hooks/utils/useImportToken";
import * as Sentry from "@sentry/react";

const MetamaskProvider = ({children}: ChildrenType) => {
    const [address, setAddress] = useState("");
    const [nonce, setNonce] = useState("");
    const [signature, setSignature] = useState("");

    const chainIdEth = process.env.REACT_APP_ETH_CHAIN as ChainIdType;
    const chainIdMatic = process.env.REACT_APP_MATIC_CHAIN as ChainIdType;

    const provider = useProvider(window.ethereum);

    const {switchNetwork, addNetwork} = useNetwork();
    const importToken = useImportToken();

    const {setIsAuthorized, setIsLoginLoaded} = useContext(UserContext);
    const {storageKeyAuthorized, getStorageByKey} = useContext(StorageContext);

    const isWalletInstalled = async () => {
        try {
            if (!provider) return;

            setIsLoginLoaded(true);

            const accounts = await provider.request({method: "eth_accounts"});

            if (accounts.length !== 0) {
                const hashAddress = sha256(accounts[0]);
                const storage = getStorageByKey(storageKeyAuthorized);

                const token = storage[hashAddress] as string;

                if (token) {
                    setAddress(accounts[0]);
                    AdminPanelServices.setToken(token);
                    setIsAuthorized(true);

                    Sentry.setUser({
                        id: accounts[0]
                    });
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoginLoaded(false);
        }
    };

    useEffect(() => {
        isWalletInstalled();
    }, []);

    const context: MetamaskContextType = {
        address,
        setAddress,

        provider,
        chainIdEth,
        chainIdMatic,

        nonce,
        setNonce,

        signature,
        setSignature,

        switchNetwork,
        addNetwork,

        importToken,
    };

    return (
        <MetamaskContext.Provider value={context}>
            {children}
        </MetamaskContext.Provider>
    );
};

export default MetamaskProvider;

export const MetamaskContext = createContext({} as MetamaskContextType);

type MetamaskContextType = {
    address: string
    setAddress: Dispatch<SetStateAction<string>>

    nonce: string
    setNonce: Dispatch<SetStateAction<string>>

    signature: string
    setSignature: Dispatch<SetStateAction<string>>

    provider: ProviderType
    chainIdEth: ChainIdType
    chainIdMatic: ChainIdType

    switchNetwork: SwitchNetworkCallbackType
    addNetwork: AddNetworkCallbackType

    importToken: UseImportTokenCallbackType
}

export type ChainIdType = "0x1" | "0x5" | "0x13881" | "0x89"