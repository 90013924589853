import {ChainIdType,} from "providers/MetamaskProvider";
import {ProviderType} from "hooks/utils/useProvider";

export const useNetwork = () => {
    const switchNetwork: SwitchNetworkCallbackType = async (provider, chainId) => {
        if (!provider) return;

        let result;

        if (provider.chainId !== chainId) {
            try {
                result = await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{
                        chainId: chainId,
                    }]
                });
            } catch (error: any) {
                console.log(error);
                result = error;
            } finally {
                if (result !== null) {
                    throw "Error Switch Network Metamask!";
                }
            }
        }
    };

    const addNetwork: AddNetworkCallbackType = async (provider, chainId, params) => {
        if (!provider) return;

        let result;

        if (provider.chainId !== chainId) {
            try {
                result = await provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [{
                        chainId: chainId,
                    }]
                });
            } catch (error: any) {
                console.log(error);

                if (error.code === 4902) {
                    try {
                        await provider.request({
                            method: "wallet_addEthereumChain",
                            params: [params]
                        })
                    } catch (e: any) {
                        console.log(e);
                    }
                }

                result = error;
            } finally {
                if (result !== null) {
                    throw "Error Network Metamask!";
                }
            }
        }
    };

    return {switchNetwork, addNetwork};
};

export type SwitchNetworkCallbackType = (provider: ProviderType, chainId: ChainIdType) => any
export type AddNetworkCallbackType = (provider: ProviderType, chainId: ChainIdType, params: NetworkParamsType) => void

type NetworkParamsType = PolygonMainnetNetworkParametersType | PolygonTestnetNetworkParametersType

type PolygonTestnetNetworkParametersType = typeof PolygonTestnetNetworkParameters
type PolygonMainnetNetworkParametersType = typeof PolygonMainnetNetworkParameters

type NetworkParametersType = {
    net_0x13881: PolygonTestnetNetworkParametersType,
    net_0x89: PolygonMainnetNetworkParametersType,
}

type NetworkParametersKeyType = keyof NetworkParametersType

const PolygonMainnetNetworkParameters = {
    chainId: "0x89",
    rpcUrls: ["https://polygon-rpc.com"],
    chainName: "Polygon Mainnet",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
    },
    blockExplorerUrls: ["https://polygonscan.com"]
};

const PolygonTestnetNetworkParameters = {
    chainId: "0x13881",
    rpcUrls: ["https://matic-mumbai.chainstacklabs.com"],
    chainName: "Mumbai",
    nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"]
}

const networkParameters: NetworkParametersType = {
    net_0x13881: PolygonTestnetNetworkParameters,
    net_0x89: PolygonMainnetNetworkParameters,
};

export const polygonNetworkParameters = networkParameters["net_" + process.env.REACT_APP_MATIC_CHAIN as NetworkParametersKeyType];