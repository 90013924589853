import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import * as Sentry from "@sentry/react";
import {ChildrenType} from "App";
import {UserFundType} from "API/AdminPanel/types";
import {sha256} from "js-sha256";
import {AdminPanelServices} from "API/AdminPanel";
import {ethers} from "ethers";
import {useLogoutUnauthorized, UseLogoutUnauthorizedType} from "hooks/utils/useLogoutUnauthorized";
import {ProviderType} from "hooks/utils/useProvider";

const UserProvider = ({children}: ChildrenType) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [isLoginLoaded, setIsLoginLoaded] = useState(false);
    const [userFund, setUserFund] = useState({} as UserFundType);

    const logoutUnauthorized = useLogoutUnauthorized();

    const context: UserContextType = {
        isAuthorized,
        setIsAuthorized,

        isLoginLoaded,
        setIsLoginLoaded,

        userFund,
        setUserFund,

        logoutUnauthorized,
    };

    return (
        <UserContext.Provider value={context}>
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;

export const UserContext = createContext({} as UserContextType);

type UserContextType = {
    isAuthorized: boolean
    setIsAuthorized: Dispatch<SetStateAction<boolean>>

    isLoginLoaded: boolean
    setIsLoginLoaded: Dispatch<SetStateAction<boolean>>

    userFund: UserFundType
    setUserFund: Dispatch<SetStateAction<UserFundType>>

    logoutUnauthorized: UseLogoutUnauthorizedType
}

export const getSignature = async (nonce: string, provider: ProviderType) => {
    const currentProvider = new ethers.providers.Web3Provider(provider);
    const signer = currentProvider.getSigner();
    return await signer.signMessage(nonce);
};

export const loginUser = async (address: string, provider: ProviderType) => {
    const responseNonce = await AdminPanelServices.getNonce(address);
    const nonce = responseNonce.data;

    const signature = await getSignature(nonce, provider);

    const responseLogin = await AdminPanelServices.login(address, signature);
    const token = responseLogin.data.token;

    if (responseLogin?.data?.token) {
        Sentry.setUser({
            id: address
        });
    }

    return {nonce, signature, token};
};


export const userLogout = (address: string, clearStorage: (key: string) => void) => {
    const hashAddress = sha256(address);
    clearStorage(hashAddress);
    window.location.reload();
};