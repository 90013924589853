import React, {createContext} from "react";
import {ChildrenType} from "App";
import {AlertModalType} from "components/Modals/Alert/ModalAlert";

const NotificationProvider = ({children}: ChildrenType) => {
    const context: NotificationContextType = {
        errorNetwork,
        errorInsufficientFundsPolygon,
        errorMoreTokensDeposit,
        errorMoreTokensWithdraw,
        errorInsufficientFunds,
        errorApprovalForAll,
        errorStaking,
        errorUnstaking,
        errorUpdateStakeDay,
        errorWithdrawFunds,
        errorWithdrawNoCoins,
        errorNoNFTs,
        errorConnectMetamask,
        errorSuspendedAccount,
        errorDepositFunds,

        successStaking,
        successUnstaking,
        successUpdateStakeDay,
        successWithdrawFunds,
        successDepositFunds,

        processingStatus,
        processingUnstake,
        processingStake,
        processingExtendTo90Days,
    };

    return (
        <NotificationContext.Provider value={context}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;

export const NotificationContext = createContext({} as NotificationContextType);

type NotificationContextType = {
    errorNetwork: AlertModalType
    errorInsufficientFundsPolygon: AlertModalType
    errorMoreTokensDeposit: AlertModalType
    errorMoreTokensWithdraw: AlertModalType
    errorInsufficientFunds: AlertModalType
    errorApprovalForAll: AlertModalType
    errorStaking: AlertModalType
    errorUnstaking: AlertModalType
    errorUpdateStakeDay: AlertModalType
    errorWithdrawFunds: AlertModalType
    errorWithdrawNoCoins: AlertModalType
    errorNoNFTs: AlertModalType
    errorConnectMetamask: AlertModalType
    errorSuspendedAccount: AlertModalType
    errorDepositFunds: AlertModalType

    successStaking: AlertModalType
    successUnstaking: AlertModalType
    successUpdateStakeDay: AlertModalType
    successWithdrawFunds: AlertModalType
    successDepositFunds: AlertModalType

    processingStatus: string
    processingUnstake: string
    processingStake: string
    processingExtendTo90Days: string
};

const errorNetwork: AlertModalType = {
    title: "Not switched Network!",
    text: "Accept switch network for correct working.",
    variant: "Error",
    link: null,
};

const errorInsufficientFundsPolygon: AlertModalType = {
    title: "Insufficient Funds!",
    text: "Not enough Matic (Polygon) tokens on your Metamask wallet to pay the gas fee for the transaction.",
    variant: "Error",
    link: null,
};

const errorMoreTokensDeposit: AlertModalType = {
    title: "Insufficient Funds!",
    text: "Not enough $FRBI to make a deposit.",
    variant: "Error",
    link: null,
};

const errorMoreTokensWithdraw: AlertModalType = {
    title: "Insufficient Funds!",
    text: "Not enough Unlocked Coins to make a withdraw.",
    variant: "Error",
    link: null,
};

const errorInsufficientFunds: AlertModalType = {
    title: "Insufficient Funds!",
    text: "Not enough ETH (Ethereum) tokens on your Metamask wallet to pay the gas fee for the transaction.",
    variant: "Error",
    link: null,
};

const errorApprovalForAll: AlertModalType = {
    title: "Something is wrong with ApprovalForAll!",
    text: "Please check your transaction block error or try again later.",
    variant: "Error",
    link: null,
};

const errorStaking: AlertModalType = {
    title: "Something is wrong with Staking!",
    text: "Please check your transaction block error or try again later.",
    variant: "Error",
    link: null,
};

const errorUnstaking: AlertModalType = {
    title: "Something is wrong with Unstaking!",
    text: "Please check your transaction block error or try again later.",
    variant: "Error",
    link: null,
};

const errorUpdateStakeDay: AlertModalType = {
    title: "Something is wrong with Update Stake Day!",
    text: "Please check your transaction block error or try again later.",
    variant: "Error",
    link: null,
};

const errorWithdrawFunds: AlertModalType = {
    title: "Something is wrong with Withdrawal!",
    text: "Please try again later.",
    variant: "Error",
    link: null,
};

const errorDepositFunds: AlertModalType = {
    title: "Something is wrong with Deposit!",
    text: "Please check your transaction block error or try again later.",
    variant: "Error",
    link: null,
};

const errorWithdrawNoCoins: AlertModalType = {
    title: "Something is wrong!",
    text: "There are no coins to withdraw.",
    variant: "Error",
    link: null,
};

const errorNoNFTs: AlertModalType = {
    title: "Don't have NFTs!",
    text: "You don't have NFTs yet, if you want to use Iggy Staking please go to the Opensea and buy them.",
    variant: "Error",
    link: {text: "Opensea IggyBoy", href: "https://opensea.io/collection/iggyboy-1athstudio"},
};

const errorConnectMetamask: AlertModalType = {
    title: "Error Metamask!",
    text: "Please install metamask extension!",
    variant: "Error",
    link: null,
};

const errorSuspendedAccount: AlertModalType = {
    title: "Suspended Account!",
    text: "You’re currently suspended due to some suspicious activity. Please contact our support on Discord to resolve the issue:",
    variant: "Error",
    link: {text: "Discord Support", href: "https://discord.gg/1athstudio"},
};


const successStaking: AlertModalType = {
    title: "Success Staking!",
    text: "Please wait a few minutes till your data is updated on blockchain. Don`t worry if your NFT hasn't changed status on Staked immediately.",
    variant: "Success",
    link: null,
};

const successUnstaking: AlertModalType = {
    title: "Success Unstaking!",
    text: "Please wait a few minutes till your data is updated on blockchain. Don`t worry if your NFT hasn't changed status on Not Staked immediately.",
    variant: "Success",
    link: null,
};

const successUpdateStakeDay: AlertModalType = {
    title: "Staking period is successfully updated!",
    text: "Please wait a few minutes till your data is updated on blockchain. Don`t worry if your staking period hasn't changed immediately.",
    variant: "Success",
    link: null,
};

const successWithdrawFunds: AlertModalType = {
    title: "Withdrawal is completed!",
    text: "Please wait a few minutes before the funds appear in your wallet.",
    variant: "Success",
    link: null,
};

const successDepositFunds: AlertModalType = {
    title: "The deposit is completed!",
    text: "Please wait a few minutes before the funds appear in your staking balance.",
    variant: "Success",
    link: null,
};

const processingStake = "Please check your Metamask. After confirming the action in Metamask your NFT will be staked. The request processing may take a few minutes.";
const processingStatus = "Please check your Metamask. After confirming the action in Metamask your NFT will be processing. The request processing may take a few minutes.";
const processingUnstake = "Please check your Metamask. After confirming the action in Metamask your NFT staking will be terminated. The request processing may take a few minutes.";
const processingExtendTo90Days = "Please check your Metamask. After confirming the action in Metamask your NFT staking and locking period will extend to the 90-days period. The request processing may take a few minutes.";