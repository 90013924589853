import React, {useContext, useEffect, useState} from "react";
import Status from "components/UI/Status/Status";
import {TransactionContext} from "providers/TransactionProvider";
import {TypeStakeNFT} from "API/AdminPanel/types";
import CardWrapper from "components/NFTs/Cards/helper/CardWrapper";
import {BulkEventsType} from "hooks/utils/useBulkSelect";
import {NFTsContext} from "providers/NFTsProvider";
import {NotificationContext} from "providers/NotificationProvider";
import ModalTerminate from "components/Modals/Confirm/ModalTerminate";
import {StorageContext} from "providers/StorageProvider";

const CardStaked = (
    {
        index_array,
        id,
        status,
        type,
        name,
        token_id,
        image,
        daysPassed,
        stakingPeriod,
        coinsReceived,
    }: TypeStakeNFT
) => {
    const {addToUnstaking, extendTo90Days} = useContext(TransactionContext);
    const {setBulkNFTs, setBulkNFTsProcessing} = useContext(NFTsContext);
    const {processingUnstake, processingExtendTo90Days} = useContext(NotificationContext);
    const {storageKeyProcessingStaked} = useContext(StorageContext);

    const [isUnstakeModalOpen, setIsUnstakeModalOpen] = useState(false);
    const [events, setEvents] = useState<BulkEventsType>({} as BulkEventsType);

    const handleOpenModalTerminate = () => {
        if (status === "unlocked") {
            if (events.isChecked) {
                setBulkNFTs(prevState => prevState.filter(item => item.token_id !== token_id && item.id !== id));
            }

            events.setProcessingInfoText(processingUnstake);
            setBulkNFTsProcessing(prevState => ([...prevState, {tokenId: token_id, id}]));
            addToUnstaking(token_id, id, type, index_array, events);
        }

        if (status === "locked") {
            setIsUnstakeModalOpen(true);
        }
    };

    const handleExtendTo90Days = () => {
        if (events.isChecked) {
            setBulkNFTs(prevState => prevState.filter(item => item.token_id !== token_id && item.id !== id));
        }

        events.setProcessingInfoText(processingExtendTo90Days);
        setBulkNFTsProcessing(prevState => ([...prevState, {tokenId: token_id, id}]));

        extendTo90Days(token_id, id, type, events);
    };

    return (
        <CardWrapper storageKey={storageKeyProcessingStaked} setCallback={setEvents} nft={{type, token_id, id}}
                     showCheckbox={stakingPeriod === 30} image={image} name={name}>
            <ul className="card-nft__info-list">
                <li className="card-nft__info-item">
                    <p className="card-nft__info-text">Status</p>
                    <Status type={status}/>
                </li>

                <li className="card-nft__info-item">
                    <p className="card-nft__info-text">Staking period</p>
                    <p>{stakingPeriod}</p>
                </li>

                <li className="card-nft__info-item">
                    <p className="card-nft__info-text">Days passed</p>
                    <p>{daysPassed}</p>
                </li>

                <li className="card-nft__info-item">
                    <p className="card-nft__info-text">Coins received</p>
                    <p>{coinsReceived}</p>
                </li>
            </ul>

            <div className="card-nft__buttons">
                {
                    stakingPeriod === 30 &&
                    <button onClick={handleExtendTo90Days} className="button button--primary">
                        Extend to 90 days
                    </button>
                }

                <button onClick={handleOpenModalTerminate} className="button button--tertiary">
                    Unstake
                </button>
            </div>

            <ModalTerminate
                isOpen={isUnstakeModalOpen} index_array={index_array} setIsOpen={setIsUnstakeModalOpen}
                type={type} id={id} tokenId={token_id} name={name}
                events={events}
            />
        </CardWrapper>
    );
};

export default CardStaked;
