import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import InputSearch from "components/UI/Inputs/InputSearch";
import Input from "components/UI/Inputs/Input";

type SortByIdsProps = {
    callback: () => void
    selected: string
    setSelected: Dispatch<SetStateAction<string>>
}

const SortByIds = ({callback, setSelected, selected}: SortByIdsProps) => {
    const [start, setStart] = useState(false);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setStart(true);
        const inputValue = e.target.value;
        setSelected(inputValue);
    };

    useEffect(() => {
        if (!start) return;

        const interval = setTimeout(callback, 1000);

        return () => clearTimeout(interval);
    }, [selected]);

    return (
        <InputSearch>
            <Input disabled={false} value={selected} onChange={handleSearch} placeholder="Search by IDs"
                   className="search__input"/>
        </InputSearch>
    );
};

export default SortByIds;