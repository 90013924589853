import React, {useContext, useEffect, useState} from "react";
import {ModalContext} from "providers/ModalProvider";
import {MetamaskContext} from "providers/MetamaskProvider";
import {loginUser, UserContext} from "providers/UserProvider";
import {AdminPanelServices} from "API/AdminPanel";
import {NotificationContext} from "providers/NotificationProvider";
import {StorageContext} from "providers/StorageProvider";

const Login = () => {
    const [isDisabled, setIsDisabled] = useState(false);

    const {setAddress, provider, setNonce, setSignature} = useContext(MetamaskContext);
    const {setIsAuthorized, setIsLoginLoaded} = useContext(UserContext);
    const {setAlertModal, setIsAlertModalOpen} = useContext(ModalContext);
    const {errorConnectMetamask, errorSuspendedAccount} = useContext(NotificationContext);
    const {setAuthorizedTokenToStorage} = useContext(StorageContext);

    const connectWallet = async () => {
        try {
            if (!provider) {
                window.open(`https://metamask.app.link/dapp/${window.location.host}`);
                setAlertModal(errorConnectMetamask);
                setIsAlertModalOpen(true);
                throw errorConnectMetamask.text;
            }

            setIsDisabled(true);

            
            const accounts: string[] = await provider.request({method: "eth_requestAccounts"});
            const account = accounts[0];

            setAddress(account);

            setIsLoginLoaded(true);

            const {nonce, signature, token} = await loginUser(account, provider);

            setAuthorizedTokenToStorage(account, token);

            setNonce(nonce);
            setSignature(signature);
            AdminPanelServices.setToken(token);

            setIsAuthorized(true);
        } catch (error: any) {
            console.log(error);

            if (error?.response?.data?.code === 1) {
                setAlertModal(errorSuspendedAccount);
                setIsAlertModalOpen(true);
            }
        } finally {
            setIsLoginLoaded(false);
            setIsDisabled(false);
        }
    };

    useEffect(() => {
        document.title = "Iggy NFTs Staking | Login";
    }, []);

    return (
        <div className="page-login">
            <div className="page-login__background"></div>
            <section className="section section-summary">
                <h1>Stake & Earn $FRBI coins today!</h1>
                <p>Why would I want $FRBI?</p>
                <ul>
                    <li>DAO voting & decision-making</li>
                    <li>Means of payment in the metaverse</li>
                    <li>An in-game coin for Game-Fi platforms</li>
                    <li>Whitelisting, NFT purchase & customization, raffles, giveaways, rewards, and much more…</li>
                </ul>
            </section>

            <section className="section section-form">
                <img className="login-logo" src="/images/logo-1ath-studio.png" alt="logo 1ath studio" />
                <img className="login-nft-staking" src="/images/nft-staking.png" alt="NFT staking" />
                <div className="form-login">
                    <h2 className="h1 form-login__title">Log in to your account</h2>
                    <button disabled={isDisabled} onClick={connectWallet} className="button button--primary">
                        Connect Metamask
                    </button>
                </div>
                <p>Make sure to connect your Metamask wallet that has 1ATH.Studio NFTs on it.</p>
            </section>

            <section className="section section-video">
                <video className="login-video" autoPlay muted loop src="/videos/coin-rotating.mp4"/>
            </section>
        </div>
    );
};

export default Login;

