import React, {useState} from "react";
import Nav from "components/Nav/Nav";
import {ChildrenType} from "App";
import ModalPrivacyPolicy from "../../Modals/Modal/ModalPrivacyPolicy";

const PanelWrapper = ({children}: ChildrenType) => {
    const year = new Date().getFullYear();
    const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);

    return (
        <div className="panel">
            <Nav/>

            <div className="panel__wrapper">
                {children}

                <div className="panel__footer">
                    <p className="panel__copyright">&copy; Copyright 2021-{year}. All rights reserved. Powered by 1ATH.Studio</p>

                    <span className="link" onClick={() => setIsPrivacyPolicyModalOpen(true)}>Privacy Policy</span>
                </div>
            </div>

            <ModalPrivacyPolicy
                isOpen={isPrivacyPolicyModalOpen} setIsOpen={setIsPrivacyPolicyModalOpen}
            />
        </div>
    );
};

export default PanelWrapper;
