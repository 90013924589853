import React, {createContext} from "react";
import {ChildrenType} from "App";
import {AddToStakingType, useStaking} from "hooks/useStaking";
import {AddToUnstakingType, useUnstaking} from "hooks/useUnstaking";
import {ExtendTo90DaysType, useExtendTo90Days} from "hooks/useExtendTo90Days";
import {useWithdrawFunds, WithdrawFundsType} from "hooks/useWithdrawFunds";
import {DepositFundsType, useDepositFunds} from "hooks/useDepositFunds";
import {AddToBulkStakingType, useBulkStaking} from "hooks/useBulkStaking";
import {BulkExtendTo90DaysType, useBulkExtendTo90Days} from "hooks/useBulkExtendTo90Days";

const TransactionProvider = ({children}: ChildrenType) => {
    const addToStaking = useStaking();
    const addToBulkStaking = useBulkStaking();
    const addToUnstaking = useUnstaking();
    const extendTo90Days = useExtendTo90Days();
    const bulkExtendTo90Days = useBulkExtendTo90Days();
    const withdrawFund = useWithdrawFunds();
    const depositFunds = useDepositFunds();

    const context: TransactionContextType = {
        addToStaking,
        addToBulkStaking,
        addToUnstaking,
        extendTo90Days,
        bulkExtendTo90Days,
        withdrawFund,
        depositFunds,
    };

    return (
        <TransactionContext.Provider value={context}>
            {children}
        </TransactionContext.Provider>
    );
};

export default TransactionProvider;

export const TransactionContext = createContext({} as TransactionContextType);

type TransactionContextType = {
    addToStaking: AddToStakingType
    addToBulkStaking: AddToBulkStakingType
    addToUnstaking: AddToUnstakingType
    extendTo90Days: ExtendTo90DaysType
    bulkExtendTo90Days: BulkExtendTo90DaysType
    withdrawFund: WithdrawFundsType
    depositFunds: DepositFundsType
}