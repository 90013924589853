declare global {
    interface Window {
        ethereum: any;
    }
}

export const useProvider: UseProviderType = (ethereum) => {
    if (ethereum?.providers) {
        const provider = ethereum.providers.find((provider: ProviderType) => provider.isMetaMask);
        return provider ? provider : null;
    } else {
        return ethereum?.isMetaMask ? ethereum : null;
    }
};

export type ProviderType = typeof window.ethereum
type UseProviderType = (ethereum: ProviderType) => ProviderType