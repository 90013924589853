import React, {useEffect} from "react";
import Balance from "components/Balance/Balance";
import Cooperation from "components/Balance/Cooperation";
import PanelWrapper from "components/Panel/helper/PanelWrapper";
import {ChildrenType} from "App";
import NFTsProvider from "providers/NFTsProvider";
import {useProvider} from "hooks/utils/useProvider";

const PanelNFTs = ({children}: ChildrenType) => {
    const provider = useProvider(window.ethereum);

    useEffect(() => {
        if (!provider) return;

        const changeAddress = () => {
            window.location.reload();
        };

        provider.on("accountsChanged", changeAddress);
    }, []);

    return (
        <PanelWrapper>
            <div className="panel__content">
                <div className="panel__information">
                    <NFTsProvider>
                        {children}
                    </NFTsProvider>
                </div>

                <div className="panel__balance">
                    <Balance/>

                    <Cooperation/>
                </div>
            </div>
        </PanelWrapper>
    );
};

export default PanelNFTs;