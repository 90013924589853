import React, {createContext} from "react";
import contractBoyABI from "contracts/boy.json";
import contractLadyABI from "contracts/lady.json";
import contractStakingABI from "contracts/staking.json";
import contractTokenABI from "contracts/token.json";
import {ChildrenType} from "App";
import {useContract, UseContractCallbackType} from "hooks/utils/useContract";
import {useBalance, UseBalanceCallbackType} from "hooks/utils/useBalance";

const ContractProvider = ({children}: ChildrenType) => {
    const CONTRACT_BOY_ADDRESS = process.env.REACT_APP_CONTRACT_BOY_ADDRESS as string;
    const CONTRACT_LADY_ADDRESS = process.env.REACT_APP_CONTRACT_LADY_ADDRESS as string;
    const CONTRACT_STAKING_ADDRESS = process.env.REACT_APP_CONTRACT_STAKING_ADDRESS as string;
    const CONTRACT_TOKEN_ADDRESS = process.env.REACT_APP_CONTRACT_TOKEN_ADDRESS as string;

    const contractBoyAddress = CONTRACT_BOY_ADDRESS.toLowerCase();
    const contractLadyAddress = CONTRACT_LADY_ADDRESS.toLowerCase();
    const contractStakingAddress = CONTRACT_STAKING_ADDRESS.toLowerCase();
    const contractTokenAddress = CONTRACT_TOKEN_ADDRESS.toLowerCase();

    const createContract = useContract();
    const getAddressBalance = useBalance();

    const context: ContractContextType = {
        createContract,
        getAddressBalance,

        contractBoyAddress,
        contractLadyAddress,
        contractStakingAddress,
        contractTokenAddress,

        contractBoyABI,
        contractLadyABI,
        contractStakingABI,
        contractTokenABI,
    };

    return (
        <ContractContext.Provider value={context}>
            {children}
        </ContractContext.Provider>
    );
};

export default ContractProvider;

export const ContractContext = createContext({} as ContractContextType);

type ContractContextType = {
    contractBoyAddress: string
    contractLadyAddress: string
    contractStakingAddress: string
    contractTokenAddress: string

    createContract: UseContractCallbackType
    getAddressBalance: UseBalanceCallbackType

    contractBoyABI: ContractABIType
    contractLadyABI: ContractABIType
    contractStakingABI: ContractABIType
    contractTokenABI: ContractABIType
}

export type ContractABIType = any[]