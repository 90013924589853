import {MetamaskContext} from "providers/MetamaskProvider";
import {useContext} from "react";
import {NotificationContext} from "providers/NotificationProvider";
import {ContractContext} from "providers/ContractProvider";
import {EventsHookType} from "hooks/useStaking";
import {StorageContext} from "providers/StorageProvider";
import {useProcessingToStorage} from "hooks/utils/useProcessingToStorage";

export const useExtendTo90Days = () => {
    const {provider, chainIdEth, address, switchNetwork} = useContext(MetamaskContext);
    const {
        errorInsufficientFunds,
        errorUpdateStakeDay,
        errorNetwork,
        successUpdateStakeDay
    } = useContext(NotificationContext);
    const {contractStakingAddress, contractStakingABI, createContract, getAddressBalance} = useContext(ContractContext);
    const {storageKeyProcessingStaked} = useContext(StorageContext);
    const processingToStorage = useProcessingToStorage();

    const callback: ExtendTo90DaysType = async (
        tokenId,
        id,
        type,
        {setIsNotificationOpen, setNotificationInfo, setIsProcessing, setBulkNFTsProcessing}
    ) => {
        try {
            setIsProcessing(true);

            try {
                await switchNetwork(provider, chainIdEth);
            } catch (e) {
                setNotificationInfo(errorNetwork);
                throw errorNetwork.text;
            }

            processingToStorage(storageKeyProcessingStaked, {tokenId, type}, true);

            const {web3Provider, contract} = createContract(provider, contractStakingAddress, contractStakingABI);

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setNotificationInfo(errorInsufficientFunds);
                throw errorInsufficientFunds.text;
            }

            const overrides = {
                gasLimit: 1_000_000,
            };

            try {
                const trx = await contract.updateStakeDay(tokenId, type, overrides);
                await trx.wait();
            } catch (error) {
                setNotificationInfo(errorUpdateStakeDay);
                throw errorUpdateStakeDay.text;
            }

            setNotificationInfo(successUpdateStakeDay);
        } catch (error) {
            console.log(error);

            processingToStorage(storageKeyProcessingStaked, {tokenId, type}, false);

            setBulkNFTsProcessing(prevState => {
                return prevState.filter(processedNFT => processedNFT.tokenId !== tokenId && processedNFT.id !== id);
            });

            setIsProcessing(false);
        } finally {
            setIsNotificationOpen(true);
        }
    };

    return callback;
};

export type ExtendTo90DaysType = (
    tokenId: string,
    id: number,
    type: number,
    events: EventsHookType,
) => void