import React from "react";
import Select, {components, ControlProps, GroupBase, SingleValue} from "react-select";
import {getSelectStyles} from "configs/styles/selectStyles";

type SortByProps = {
    selected: SelectOptionType
    onChange: OnChangeSelectType<SelectOptionType>
    text: string
    options: SelectOptionType[]
}

const SortBy = ({text, options, selected, onChange}: SortByProps) => {
    const {styles, customText} = getSelectStyles();

    const Control = ({children, ...props}: ControlProps<SelectOptionType, false, GroupBase<SelectOptionType>>) => (
        <components.Control {...props}>
            <p style={customText}>{text}</p>
            {children}
        </components.Control>
    );

    return (
        <Select
            value={selected}
            onChange={onChange as OnChangeSelectType<SingleValue<SelectOptionType>>}
            options={options}
            styles={styles}
            components={{Control}}
        />
    );
};

export default SortBy;

export type SelectOptionType = { value: string, label: string }
export type OnChangeSelectType<T> = (option: T) => void