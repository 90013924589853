import React from "react";
import icons from "components/UI/icons";

type InputSearchProps = {
    children: JSX.Element | React.ReactNode
}

const InputSearch = (
    {children}: InputSearchProps
) => {
    const {IconSearch} = icons;

    return (
        <div className="search">
            <label className="search__label">
                <span className="search__icon">
                    <IconSearch/>
                </span>

                {children}
            </label>
        </div>
    );
};

export default InputSearch;