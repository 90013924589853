import React from "react";
import {ModalOpenStatus} from "providers/ModalProvider";
import ModalWrapper from "../helper/ModalWrapper";

const ModalPrivacyPolicy = ({setIsOpen, isOpen}: ModalOpenStatus) => {
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <ModalWrapper isOpen={isOpen} closeModal={closeModal}>
            <div onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()} className="modal__wrapper modal__alert" style={{maxWidth: 800, overflowY: 'auto', height: '100%'}}>
                <button onClick={() => setIsOpen(false)} className="modal__btn-close">close</button>

                <div className="modal__header">
                    <h3 className="h1 modal__title">Privacy Policy</h3>
                </div>
                <div className="modal__body document">
                    <h2>PRIVACY POLICY</h2>

                    <p>At 1ATH.Studio, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by 1ATH.Studio and how we use it.</p>
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in 1ATH.Studio. This policy is not applicable to any information collected offline or via channels other than this website.</p>
                    <p>By leveraging our services, you're agreeing to our Privacy Policy, which forms an integral part of our Legal Terms. You understand that our servers are situated across different nations, each with its distinctive data protection regulations. For users outside these hosting regions, you consent to data transfer and processing in the aforementioned nations upon continued use of our Services.</p>
                    <p>We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the United States, United Kingdom, Canada, Australia, China, Germany, France, and the Netherlands, and the list may be shortened or expanded at any time. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the United States, United Kingdom, Canada, Australia, China, Germany, France, and the Netherlands, then through your continued use of the Services, you are transferring your data fully or partially to the countries included but not limited to the above-mentioned ones. By doing so, you expressly consent to have your data transferred and processed.</p>
                    <p>This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they share and/or collect in 1ATH.Studio. This policy is not applicable to any information collected offline or via channels other than this website.</p>

                    <h3>Consent</h3>

                    <p>By using our website, you hereby consent to our Privacy Policy and agree to the Terms and Conditions.</p>

                    <h3>Information we collect</h3>

                    <p>The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.</p>

                    <p>If you contact us directly, we may receive additional information about you, the contents of the message and/or attachments you may send us, and any other information you may choose to provide and disclose to us.</p>

                    <p>When you log in or register on our website, we and used third-party services may get some additional information you consent to provide. We may ask you for additional information to be able to deliver the services and product, to protect and prevent the activities including but not limited to scam, fraud, any kind of losses, or similar.</p>

                    <h3>How we use your information</h3>

                    <p>We use the information we collect in various ways, including to:</p>

                    <ul>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>

                    <h3>Log Files</h3>
                    <p>1ATH.Studio follows a standard procedure of using log files. These files log visitors when they visit websites or use services and products. All hosting companies do this and a part of hosting services' analytics and marketing. The purpose of the information is for analyzing trends, administering the site, security, marketing, and similar.</p>

                    <h3>Advertising Partners Privacy Policies</h3>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of 1ATH.Studio.</p>
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on 1ATH.Studio, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>

                    <h3>Cookie Policy</h3>
                    <p>1ATH.Studio uses cookies and similar technologies on our website. Cookies are small data files that are served by our platform and stored on your device. Our site uses cookies dropped by us or third parties for a variety of purposes, including the operation and personalization of the website.</p>
                    <p>Cookies may be used to track how you use the site to target ads to you on other websites, and to enhance your user experience by remembering your preferences and settings. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or service.</p>
                    <p>Our third-party partners may use cookies and similar technologies in order to collect and receive information from our website and elsewhere on the internet, and use that information to provide measurement services and targeted ads.</p>
                    <p>For more information about cookies, and how to disable cookies, please visit www.allaboutcookies.org. If you wish to opt-out of interest-based advertising, please visit www.youronlinechoices.eu (for European visitors) or www.aboutads.info/choices (for U.S. visitors).</p>
                    <p>Please note that we have no control over the cookies that are used by third-party advertisers. Therefore, you should consult the respective privacy policies of these third-party ad servers for more detailed information on their practices as well as for instructions about how to opt out of certain practices.</p>
                    <p>By using our website, you consent to the use of cookies and similar technologies as described above. Your consent applies to all 1ATH.Studio websites, services and products</p>


                    <h3>Third Party Privacy Policies</h3>
                    <p>1ATH.Studio's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites.</p>

                    <h3>CCPA Privacy Rights (Do Not Sell My Personal Information)</h3>
                    <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                    <ul>
                        <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                        <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                        <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                        <li>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.</li>
                    </ul>

                    <h3>GDPR Data Protection Rights</h3>
                    <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                    <ul>
                        <li>The right to access – You have the right to request copies of your personal data.</li>
                        <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.</li>
                        <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                        <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                        <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                        <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                    </ul>
                    <p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us. We may charge a fee for any of these services.</p>

                    <h3>Children’s information and age restrictions</h3>
                    <p>1ATH.Studio is designed for use by individuals aged 18 and above. The content and services provided by 1ATH.Studio are not intended for, and should not be accessed or used by, individuals under the age of 18.</p>
                    <p>Parents, guardians, and supervising adults bear the responsibility for monitoring and guiding the online activities of their children and wards. They must ensure that children under their supervision do not access or use 1ATH.Studio. In the event that we become aware that 1ATH.Studio has been accessed or used by an individual under the age of 18, we reserve the right to take appropriate steps in response, but are under no obligation to remove or delete any information.</p>
                    <p>By using 1ATH.Studio, you confirm that you are at least 18 years old and accept responsibility for ensuring that all users of your device meet these age requirements. Any breach of this clause is a violation of our Terms and Conditions.</p>

                    <h3>Updates & Alterations:</h3>
                    <p>1ATH.Studio holds the prerogative to revise this policy periodically. Users are encouraged to revisit this section to remain updated on any modifications.</p>

                    <h3>Reach Out:</h3>
                    <p>For any uncertainties or inquiries about our Privacy Policy and Terms & Conditions, or to exercise your rights as mentioned, please connect with us.</p>

                    <button className="button" style={{margin: '0 auto'}} onClick={closeModal}>Close</button>
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ModalPrivacyPolicy;