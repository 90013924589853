import React, {ChangeEvent} from "react";
import Preview from "components/Preview/Preview";
import {useResize} from "hooks/utils/useResize";

type CardNFTProps = {
    image: string
    name: string
    children: React.ReactNode
    isDisabled: boolean
    isChecked: boolean
    showCheckbox: boolean
    onChange?: () => void
}

const CardNFT = (
    {
        image,
        name,
        children,
        isDisabled,
        isChecked,
        showCheckbox,
        onChange = () => {}
    }: CardNFTProps
) => {
    const {ref, size} = useResize();

    return (
        <div className={`card-nft ${isChecked ? "checked": ""} ${showCheckbox ? "show-checkbox": ""}`}>
            <div style={{height: size}} ref={ref} className={`card-nft__preview ${isDisabled ? "disabled" : ""}`} onMouseDown={(e) => {e.preventDefault(); onChange()}}>
                <Preview image={image}/>
            </div>

            <h3 className="card-nft__name h2">{name}</h3>

            {children}
        </div>
    );
};

export default CardNFT;
