import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {ModalContext} from "providers/ModalProvider";
import {TransactionContext} from "providers/TransactionProvider";
import {UserContext} from "providers/UserProvider";
import Input from "components/UI/Inputs/Input";
import ModalDepositBuilder from "components/Modals/helper/helper/ModalDepositBuilder";

type ModalWithdrawProps = {
    setIsProcessing: Dispatch<SetStateAction<boolean>>
}

const ModalWithdraw = ({setIsProcessing}: ModalWithdrawProps) => {
    const [minValue] = useState(10);
    const [withdrawValue, setWithdrawValue] = useState(0);

    const {setIsWithdrawModalOpen, isWithdrawModalOpen} = useContext(ModalContext);
    const {userFund} = useContext(UserContext);
    const {withdrawFund} = useContext(TransactionContext);

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = +e.target.value;

        if (!isNaN(value) && value <= 999999999) {
            setWithdrawValue(value);
        }
    };

    const onCancelModal = () => {
        setIsWithdrawModalOpen(false);
        setWithdrawValue(0);
    };

    const setAllUnlockedCoins = () => {
        const coins = userFund.unlocked;
        setWithdrawValue(coins);
    };

    const onWithdraw = async () => {
        onCancelModal();
        withdrawFund(+withdrawValue, setIsProcessing);
    };

    return (
        <ModalDepositBuilder
            isOpen={isWithdrawModalOpen}
            closeModal={onCancelModal}
            title="Withdraw Balance"
            buttons={
                <div className="modal__buttons">
                    <button onClick={onCancelModal} className="modal__btn button button--tertiary">
                        Cancel
                    </button>

                    <button disabled={withdrawValue < minValue} onClick={onWithdraw}
                            className="modal__btn button button--primary">
                        Withdraw
                    </button>
                </div>
            }>
            <div className="modal__inner modal__inner--flex-item">
                <button onClick={setAllUnlockedCoins} className="modal__btn button button--secondary">
                    All
                </button>

                <Input disabled={false} value={withdrawValue} onChange={handleChangeValue}
                       placeholder="Withdraw your coins"/>
            </div>

            <p className="modal__text-info">* 5 $FRBI to be deducted for gas fee payment</p>
            <p className="modal__text-info">* Min withdrawal amount is 10 $FRBI</p>
        </ModalDepositBuilder>
    );
};

export default ModalWithdraw;
