import React, {createContext, Dispatch, SetStateAction, useState} from "react";
import {ChildrenType} from "App";
import {ContractTypes} from "API/AdminPanel/types";

const NFTsProvider = ({children}: ChildrenType) => {
    const [bulkNFTs, setBulkNFTs] = useState<BulkNFTsType[]>([]);
    const [bulkNFTsProcessing, setBulkNFTsProcessing] = useState<BulkStatusType>([]);

    const [isAllNFTsLoading, setIsAllNFTsLoading] = useState(true);

    const context: NFTsContextType = {
        bulkNFTs,
        setBulkNFTs,

        bulkNFTsProcessing,
        setBulkNFTsProcessing,

        isAllNFTsLoading,
        setIsAllNFTsLoading,
    };

    return (
        <NFTsContext.Provider value={context}>
            {children}
        </NFTsContext.Provider>
    );
};

export default NFTsProvider;

export const NFTsContext = createContext({} as NFTsContextType);

type NFTsContextType = {
    bulkNFTs: BulkNFTsType[]
    setBulkNFTs: Dispatch<SetStateAction<BulkNFTsType[]>>

    bulkNFTsProcessing: BulkStatusType
    setBulkNFTsProcessing: Dispatch<SetStateAction<BulkStatusType>>

    isAllNFTsLoading: boolean
    setIsAllNFTsLoading: Dispatch<SetStateAction<boolean>>
}

export type BulkNFTsType = { token_id: string, id: number, type: ContractTypes }
export type BulkStatusType = BulkProcessingNFTsType[]
export type BulkProcessingNFTsType = { tokenId: string, id: number }