import React, {createRef, useEffect} from "react";
import {ModalWrapperProps} from "components/Modals/helper/ModalWrapper";

const ModalAlertWrapper = (
    {
        children,
        isOpen,
        closeModal,
    }: ModalWrapperProps
) => {
    const modalRef = createRef<HTMLDivElement>();

    const keyDownHandler = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Escape") {
            closeModal(false);
        }
    };

    useEffect(() => {
        modalRef?.current?.focus();
    }, [modalRef]);

    return (
        <>
            {
                isOpen
                    ? <div className="modal" tabIndex={0} ref={modalRef} onKeyDown={keyDownHandler}>
                        {children}
                    </div>
                    : null
            }
        </>
    );
};

export default ModalAlertWrapper;