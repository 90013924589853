import {Dispatch, SetStateAction, useContext} from "react";
import {ModalContext} from "providers/ModalProvider";
import {NotificationContext} from "providers/NotificationProvider";
import {MetamaskContext} from "providers/MetamaskProvider";
import {ContractContext} from "providers/ContractProvider";
import {ethers} from "ethers";
import {polygonNetworkParameters} from "hooks/utils/useNetwork";

export const useDepositFunds = () => {
    const {setAlertModal, setIsAlertModalOpen} = useContext(ModalContext);
    const {address, provider, chainIdMatic, addNetwork, importToken} = useContext(MetamaskContext);
    const {
        errorDepositFunds,
        errorInsufficientFundsPolygon,
        errorMoreTokensDeposit,
        successDepositFunds,
        errorNetwork,
    } = useContext(NotificationContext);
    const {contractTokenAddress, contractTokenABI, createContract, getAddressBalance} = useContext(ContractContext);

    const callback: DepositFundsType = async (
        funds,
        setIsProcessing,
    ) => {
        try {
            setIsProcessing(true);

            try {
                await addNetwork(provider, chainIdMatic, polygonNetworkParameters);
            } catch (e) {
                setAlertModal(errorNetwork);
                throw errorNetwork.text;
            }

            const {web3Provider, contract} = createContract(provider, contractTokenAddress, contractTokenABI);

            const addressTokenBalance = await contract.balanceOf(address);

            if (funds > +addressTokenBalance) {
                setAlertModal(errorMoreTokensDeposit);
                throw errorMoreTokensDeposit.text;
            }

            const balance = await getAddressBalance(web3Provider, address);

            if (!balance) {
                setAlertModal(errorInsufficientFundsPolygon);
                throw errorInsufficientFundsPolygon.text;
            }

            const tokenType = "ERC20";
            const tokenSymbol = "FRBI";
            const tokenDecimal = 0;

            await importToken(provider, tokenType, contractTokenAddress, tokenSymbol, tokenDecimal)

            const numberOfTokens = ethers.utils.parseUnits((funds * 10).toString(), tokenDecimal);

            try {
                const trx =await contract.transfer(contractTokenAddress, numberOfTokens);
                await trx.wait();
            } catch (error) {
                setAlertModal(errorDepositFunds);
                throw errorDepositFunds.text;
            }

            setAlertModal(successDepositFunds);
        } catch (error) {
            console.log(error);
        } finally {
            setIsProcessing(false);
            setIsAlertModalOpen(true);
        }
    };

    return callback;
};

export type DepositFundsType = (
    funds: number,
    setIsProcessing: Dispatch<SetStateAction<boolean>>,
) => void