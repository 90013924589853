import React from "react";
import ModalWrapper, {ModalType} from "components/Modals/helper/ModalWrapper";

interface ModalConfirmBuilderProps extends ModalType {
    title: string
    buttons: React.ReactNode
    children: React.ReactNode
}

const ModalDepositBuilder = (
    {
        children,
        title,
        isOpen,
        closeModal,
        buttons,
    }: ModalConfirmBuilderProps
) => {
    return (
        <ModalWrapper offFocus={true} isOpen={isOpen} closeModal={closeModal}>
            <div onClick={
                (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
            } className="modal__wrapper modal__wrapper--small">
                <div className="modal__header">
                    <h3 className="h2 modal__title">{title}</h3>
                </div>
                {children}
                <div className="modal__footer">
                    {buttons}
                </div>
            </div>
        </ModalWrapper>
    );
};

export default ModalDepositBuilder;