import React from "react";
import icons from "components/UI/icons";
import {StatusType} from "components/UI/Status/Status";

type CoinBuilderType = {
    type: StatusType
    icon: CoinIconType
    description: string
    counter: number
}

const Coin = (
    {
        type,
        icon,
        description,
        counter,
    }: CoinBuilderType
) => {
    const Icon = icons[icon];

    return (
        <div className="coin">
            <div className={`coin__icon icon icon--${type}`}>
                <Icon/>
            </div>

            <div className="coin__info">
                <div className="coin__counter h1">{counter}</div>
                <p className="coin__description">{description}</p>
            </div>
        </div>
    );
};

export default Coin;

type CoinIconType = keyof Pick<typeof icons, "IconSuccess" | "IconProcess">
