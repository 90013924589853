import React, {Dispatch, SetStateAction} from "react";
import InputSelect, {OnChangeSelectType, SelectOptionType} from "components/UI/Inputs/InputSelect";

type SortByProps = {
    callback: () => void;
    selected: SelectOptionType
    setSelected: Dispatch<SetStateAction<SelectOptionType>>
    options: SelectOptionType[]
}

const SortBy = ({options, callback, selected, setSelected}: SortByProps) => {
    const handleChange: OnChangeSelectType<SelectOptionType> = (option) => {
        callback();
        setSelected(option);
    };

    return (
        <InputSelect selected={selected} onChange={handleChange} options={options} text={"Sort by:"}/>
    );
};

export default SortBy;