import {StylesConfig} from "react-select";
import {SelectOptionType} from "components/UI/Inputs/InputSelect";

export const getSelectStyles = () => {
    const styles: StylesConfig<SelectOptionType, false> = {
        control: (provided, state) => {
            return {
                ...provided,
                ...stylesCustom.customControl(state.menuIsOpen)
            };
        },

        indicatorSeparator: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customIndicatorSeparator
            };
        },

        indicatorsContainer: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customIndicatorsContainer
            };
        },

        input: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customInput
            }
        },

        placeholder: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customPlaceholder
            }
        },

        valueContainer: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customValueContainer
            }
        },

        menu: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customMenu
            }
        },

        menuList: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customMenuList
            }
        },

        option: (provided, state) => {
            return {
                ...provided,
                ...stylesCustom.customOption(state.isSelected)
            }
        },

        singleValue: (provided) => {
            return {
                ...provided,
                ...stylesCustom.customSingleValue
            }
        },
    };

    const stylesCustom = {
        customControl: (isOpen: boolean) => ({
            padding: "8px 12px",
            borderRadius: "8px",
            borderColor: "transparent",
            backgroundColor: "#363940",
            cursor: "pointer",
            transition: "border-color 0.15s linear",
            boxShadow: "none",
            "div[class$='-indicatorContainer']": {
                transition: "transform 0.15s linear",
                transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
            },
            "&:hover": {
                borderColor: "#ba62ff",
            },
            "&:focus": {
                borderColor: "#ba62ff",
            }
        }),

        customIndicatorSeparator: {
            display: "none",
        },

        customValueContainer: {
            padding: "0",
        },

        customPlaceholder: {
            margin: "0",
            display: "none",
        },

        customIndicatorsContainer: {
            "div": {
                color: "#c9cbd1",
                padding: "0",

                "&:hover": {
                    color: "#c9cbd1",
                }
            },
        },

        customSingleValue: {
            margin: "0",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#ffffff",
        },

        customInput: {
            margin: "0",
            padding: "0",
        },

        customMenu: {
            margin: "4px 0",
            boxShadow: "none",
            borderRadius: "8px",
            backgroundColor: "#363940",
            overflow: "hidden",
        },

        customMenuList: {
            padding: "0",
        },

        customOption: (isSelected: boolean) => ({
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#ffffff",
            backgroundColor: isSelected ? "rgba(199, 128, 255, 0.2)" : "transparent",
            padding: "8px 12px",
            cursor: "pointer",
            transition: "background-color 0.15s linear",
            "&:hover": {
                backgroundColor: isSelected ? "rgba(199, 128, 255, 0.2)" : "rgba(199, 128, 255, 0.3)",
            },
            "&:active": {
                backgroundColor: "rgba(199, 128, 255, 0.4)",
            }
        }),
    }

    const customText = {
        paddingRight: "8px",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "22px",
            color: "#b7b9bf"
    };

    return {
        styles,
        stylesCustom,
        customText,
    };
};