const STAKING_URL = process.env.REACT_APP_API_STAKING_URL;
const AUTH_URL = process.env.REACT_APP_API_AUTH_URL;

export const GET_NONCE = AUTH_URL + "get-nonce";
export const LOGIN = AUTH_URL + "login";

export const GET_NFT_STAKE = STAKING_URL + "get-nft-stake";
export const GET_ALL_NFT_STAKE = STAKING_URL + "get-all-nft-stake";

export const GET_NFT_UNSTAKE = STAKING_URL + "get-nft-unstake";
export const GET_ALL_NFT_UNSTAKE = STAKING_URL + "get-all-nft-unstake";

export const IS_APPROVAL = STAKING_URL + "is-approval";
export const ADD_APPROVAL = STAKING_URL + "add-approval";

export const GET_USER_FUND = STAKING_URL + "get-user-fund";
export const SEND_FUND = STAKING_URL + "send-fund";

export const CHECK_WITHDRAW = STAKING_URL + "check-withdraw";
