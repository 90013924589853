import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {ModalContext} from "providers/ModalProvider";
import ModalDepositBuilder from "components/Modals/helper/helper/ModalDepositBuilder";
import Input from "components/UI/Inputs/Input";
import {TransactionContext} from "providers/TransactionProvider";

type ModalDepositProps = {
    setIsProcessing: Dispatch<SetStateAction<boolean>>
}

const ModalDeposit = ({setIsProcessing}: ModalDepositProps) => {
    const [depositValue, setDepositValue] = useState("");

    const {setIsDepositModalOpen, isDepositModalOpen} = useContext(ModalContext);
    const {depositFunds} = useContext(TransactionContext);

    const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        if (!isNaN(+value) && +value <= 999999999) {
            setDepositValue(value);
        }
    };

    const cancelModal = () => {
        setIsDepositModalOpen(false);
        setDepositValue("");
    };

    const deposit = async () => {
        cancelModal();
        await depositFunds(+depositValue, setIsProcessing);
    };

    return (
        <ModalDepositBuilder
            isOpen={isDepositModalOpen}
            closeModal={cancelModal}
            title="Deposit Balance"
            buttons={
                <div className="modal__buttons">
                    <button onClick={cancelModal} className="modal__btn button button--tertiary">
                        Cancel
                    </button>

                    <button disabled={+depositValue === 0} onClick={deposit} className="modal__btn button button--primary">
                        Deposit
                    </button>
                </div>
            }>
            <Input disabled={false} value={depositValue} onChange={handleChangeValue} placeholder="Deposit your coins"/>
        </ModalDepositBuilder>
    );
};

export default ModalDeposit;