import React, {useState} from "react";
import {AdminPanelServices} from "API/AdminPanel";
import BulkStaking from "components/Bulk/BulkStaking";
import {SelectOptionType} from "components/UI/Inputs/InputSelect";
import SortByIds from "components/Sort/SortByIds";
import SortBy from "components/Sort/SortBy";
import PanelNFTsWrapper, {ResetPanelCallbackType} from "components/Panel/helper/PanelNFTsWrapper";
import {GetNFTsUnstakeResponseType, TypeUnstakeNFT} from "API/AdminPanel/types";

const NotStakedPanel = () => {
    const [selectedOptionByOrder, setSelectedOptionByOrder] = useState(optionsByOrder[0]);
    const [selectedOptionByCollection, setSelectedOptionByCollection] = useState(optionsByCollection[0]);
    const [selectedByIds, setSelectedByIds] = useState("");

    const [reset, setReset] = useState<ResetPanelCallbackType>({} as ResetPanelCallbackType);

    const callback = async (page: number) => {
        const byOrder = selectedOptionByOrder.value;
        const byCollection = +selectedOptionByCollection.value;

        return await AdminPanelServices.getNFTsUnstake(page, byOrder, byCollection, selectedByIds);
    };

    return (
        <PanelNFTsWrapper<TypeUnstakeNFT, GetNFTsUnstakeResponseType>
            resetCallback={setReset} callback={callback} title="Non-Staked" type="NotStaked">
            <div className="nft__sort">
                <SortByIds callback={reset} selected={selectedByIds} setSelected={setSelectedByIds}/>

                <SortBy callback={reset} options={optionsByOrder}
                        selected={selectedOptionByOrder} setSelected={setSelectedOptionByOrder}/>
                <SortBy callback={reset} options={optionsByCollection}
                        selected={selectedOptionByCollection} setSelected={setSelectedOptionByCollection}/>
            </div>

            <BulkStaking byIds={selectedByIds} byCollection={+selectedOptionByCollection.value}/>
        </PanelNFTsWrapper>
    );
};

export default NotStakedPanel;

const optionsByOrder: SelectOptionType[] = [
    {label: 'Alphabetical A-Z', value: 'asc'},
    {label: 'Alphabetical Z-A', value: 'desc'},
];

const optionsByCollection: SelectOptionType[] = [
    {label: 'IggyBoy', value: '1'},
    {label: 'IggyLady', value: '2'},
];
