import React, {useContext, useState} from "react";
import {EventsHookType, StakingDaysType} from "hooks/useStaking";
import {TypeUnstakeNFT} from "API/AdminPanel/types";
import CardWrapper, {GetEventsCallbackType} from "components/NFTs/Cards/helper/CardWrapper";
import {BulkEventsType} from "hooks/utils/useBulkSelect";
import {TransactionContext} from "providers/TransactionProvider";
import {NFTsContext} from "providers/NFTsProvider";
import {NotificationContext} from "providers/NotificationProvider";
import {StorageContext} from "providers/StorageProvider";

const CardNotStaked = (
    {
        id,
        contract_address,
        name,
        image,
        type,
        token_id,
    }: TypeUnstakeNFT
) => {
    const {addToStaking} = useContext(TransactionContext);
    const {setBulkNFTs, setBulkNFTsProcessing} = useContext(NFTsContext);
    const {processingStake} = useContext(NotificationContext);
    const {storageKeyProcessingNotStaked} = useContext(StorageContext);

    const [events, setEvents] = useState<BulkEventsType>({} as BulkEventsType);

    const staking = (days: StakingDaysType) => {
        if (events.isChecked) {
            setBulkNFTs(prevState => prevState.filter(item => item.token_id !== token_id && item.id !== id));
        }

        events.setProcessingInfoText(processingStake);
        setBulkNFTsProcessing(prevState => ([...prevState, {tokenId: token_id, id}]));

        addToStaking(days, token_id, id, type, contract_address, events);
    };

    return (
        <CardWrapper storageKey={storageKeyProcessingNotStaked} setCallback={setEvents} nft={{type, token_id, id}}
                     showCheckbox={true} image={image} name={name}>
            <div className="card-nft__buttons">
                <button onClick={() => staking(90)} className="button button--primary">
                    Stake for 90 days
                </button>

                <button onClick={() => staking(30)} className="button button--secondary">
                    Stake for 30 days
                </button>
            </div>
        </CardWrapper>
    );
};

export default CardNotStaked;
